// AboutUs.js
import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslations } from '../contexts/TranslationsContext';

const AboutUs = ({ language }) => {
  const translations = useTranslations();
  
  return (
    
    <Box sx={{ padding: 4, backgroundColor: '#E5A0FF', borderRadius: '8px', margin: '16px 0', boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)', textAlign: 'center' }}>
      <Typography variant="h3" gutterBottom sx={{ fontWeight: 'bold', color: '#2E073F' }}>
        {translations.aboutUsTitle}
      </Typography>
      <Typography variant="h6" sx={{ color: '#2E073F' }}>
        {translations.aboutUsDescription}
      </Typography>
    </Box>
  );
};

export default AboutUs;
