import React, { useEffect, useState } from 'react';
import { Box, Typography, Card, CardContent, Button } from '@mui/material';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router-dom';

const ServicesContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    gap: theme.spacing(2),
    padding: theme.spacing(2),
}));

const ServiceCard = styled(Card)(({ theme }) => ({
    width: '300px',
    backgroundColor: '#f5f5f5',
    border: '2px solid #E5A0FF', /* Border color */
    borderRadius: '8px',
    boxShadow: theme.shadows[2],
    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    '&:hover': {
        transform: 'scale(1.05)',
        boxShadow: theme.shadows[4],
    },
}));

const ServiceTitle = styled(Typography)(({ theme }) => ({
    fontSize: '1.5rem',
    fontWeight: 'bold',
    color: '#333',
    marginBottom: theme.spacing(1),
}));

const ServiceDescription = styled(Typography)(({ theme }) => ({
    fontSize: '1rem',
    color: '#666',
    flexGrow: 1,
}));

const ServiceButton = styled(Button)(({ theme }) => ({
    marginTop: '10px',
    alignSelf: 'center',
}));

const ServiceCards = ({ language }) => {
    const [services, setServices] = useState([]);
    const navigate = useNavigate();
    const apiUrl = process.env.REACT_APP_API_URL;

    const translations = {
        en: {
            learnMore: 'Learn More',
        },
        jp: {
            learnMore: 'もっと詳しく',
        },
    };

    useEffect(() => {
        // Fetch the service information from the backend API
        fetch(`${apiUrl}/data/services`)
          .then(response => {
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            return response.json();
          })
          .then(data => {
            if (data) {
              setServices(data.services);
            }
          })
          .catch(error => console.error('Error fetching service price:', error));
      }, [language, apiUrl]);
    
    const handleNavigate = (url) => {
        navigate(url);
    };

    return (
        <ServicesContainer>
            {services.map((service, index) => (
                <ServiceCard key={index}>
                    <CardContent sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                        <ServiceTitle>{service.title[language]}</ServiceTitle>
                        <ServiceDescription>{service.description[language]}</ServiceDescription>
                        <ServiceButton
                            variant="contained"
                            color="primary"
                            onClick={() => handleNavigate(service.url)}
                        >
                            {translations[language].learnMore}
                        </ServiceButton>
                    </CardContent>
                </ServiceCard>
            ))}
        </ServicesContainer>
    );
};

export default ServiceCards;