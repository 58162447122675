import React, { useState } from 'react';
import { Button, TextField, Typography, Box, Alert, IconButton, InputAdornment, Checkbox, FormControlLabel } from '@mui/material';
import { Link } from 'react-router-dom';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { styled } from '@mui/system';
import { useTranslations } from '../contexts/TranslationsContext';

const StyledBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2),
    backgroundColor: '#ffffff',
    borderRadius: '8px',
    boxShadow: theme.shadows[2],
    maxWidth: 400,
    margin: 'auto',
}));

const SignupButton = styled(Button)(({ theme }) => ({
    marginTop: theme.spacing(2),
}));

const LoginLink = styled(Typography)(({ theme }) => ({
    marginTop: theme.spacing(2),
}));

const Signup = ({ language }) => {
    const t = useTranslations();
    const apiUrl = process.env.REACT_APP_API_URL;
    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [errors, setErrors] = useState({});
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [agreeToTerms, setAgreeToTerms] = useState(false);

    const validateForm = () => {
        const newErrors = {};
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const passwordPattern = /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

        if (!fullName) newErrors.fullName = t.fullNameRequired;
        if (!email) {
            newErrors.email = t.emailRequired;
        } else if (!emailPattern.test(email)) {
            newErrors.email = t.emailInvalid;
        }
        if (!password) {
            newErrors.password = t.passwordRequired;
        } else if (!passwordPattern.test(password)) {
            newErrors.password = t.passwordInvalid;
        }
        if (password !== confirmPassword) {
            newErrors.confirmPassword = t.passwordMismatch;
        }

        setErrors(newErrors);
        return newErrors;
    };

    const handleInputChange = (setter, field) => (e) => {
        setter(e.target.value);
        setErrors((prevErrors) => ({ ...prevErrors, [field]: '' }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formErrors = validateForm();

        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors);
            setErrorMessage('');
            setSuccessMessage('');
        } else {
            try {
                const response = await fetch(`${apiUrl}/users/create_user`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        full_name: fullName,
                        email: email,
                        password: password,
                        language: language,
                    }),
                });

                if (response.ok) {
                    setSuccessMessage(t.signupSuccess);
                    setErrorMessage(''); // Clear error message
                    setErrors({});
                    // Reset fields
                    setFullName('');
                    setEmail('');
                    setPassword('');
                    setConfirmPassword('');
                    setAgreeToTerms(false);
                } else {
                    const data = await response.json();
                    const errorDetail = data.detail;
                    let translatedError = t.signupFailed;

                    if (errorDetail === "Email already registered") {
                        translatedError = t.emailAlreadyRegistered;
                    }

                    setErrorMessage(translatedError);
                    setSuccessMessage(''); // Clear success message
                    setErrors({}); // Clear input field errors
                }
            } catch (error) {
                setErrorMessage(t.signupFailed);
                setSuccessMessage(''); // Clear success message
                setErrors({}); // Clear input field errors
            }
        }
    };

    const toggleShowPassword = () => {
        setShowPassword((show) => !show);
    };

    const toggleShowConfirmPassword = () => {
        setShowConfirmPassword((show) => !show);
    };

    return (
        <StyledBox>
            <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold', color: '#2E073F' }}>{t.signupTitle}</Typography>
            {successMessage && <Alert severity="success">{successMessage}</Alert>}
            {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
            <form onSubmit={handleSubmit} style={{ width: '100%' }}>
                <TextField 
                    label={t.fullName} 
                    variant="outlined" 
                    margin="normal" 
                    fullWidth 
                    value={fullName} 
                    onChange={handleInputChange(setFullName, 'fullName')} 
                    error={!!errors.fullName} 
                    helperText={errors.fullName} 
                />
                <TextField 
                    label={t.email} 
                    variant="outlined" 
                    margin="normal" 
                    fullWidth 
                    value={email} 
                    onChange={handleInputChange(setEmail, 'email')} 
                    error={!!errors.email} 
                    helperText={errors.email} 
                />
                <TextField 
                    label={t.password} 
                    variant="outlined" 
                    type={showPassword ? 'text' : 'password'} 
                    margin="normal" 
                    fullWidth 
                    value={password} 
                    onChange={handleInputChange(setPassword, 'password')} 
                    error={!!errors.password} 
                    helperText={errors.password} 
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={toggleShowPassword}
                                    edge="end"
                                >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
                <TextField 
                    label={t.confirmPassword} 
                    variant="outlined" 
                    type={showConfirmPassword ? 'text' : 'password'} 
                    margin="normal" 
                    fullWidth 
                    value={confirmPassword} 
                    onChange={handleInputChange(setConfirmPassword, 'confirmPassword')} 
                    error={!!errors.confirmPassword} 
                    helperText={errors.confirmPassword} 
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={toggleShowConfirmPassword}
                                    edge="end"
                                >
                                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={agreeToTerms}
                            onChange={(e) => setAgreeToTerms(e.target.checked)}
                            name="agreeToTerms"
                            color="primary"
                        />
                    }
                    label={
                        language === 'jp' ? (
                            <Typography variant="body2">
                                <Link to="/privacy-policy" target="_blank" rel="noopener noreferrer">
                                    {t['privacy_policy.title']}
                                </Link>{' '}
                                    と{' '}
                                <Link to="/term-of-service" target="_blank" rel="noopener noreferrer">
                                    {t['tos.title']}
                                </Link>
                                に同意します
                            </Typography>
                        ) : (
                            <Typography variant="body2">
                                I agree to the{' '}
                                <Link to="/privacy-policy" target="_blank" rel="noopener noreferrer">
                                    {t['privacy_policy.title']}
                                </Link>{' '}
                                and{' '}
                                <Link to="/term-of-service" target="_blank" rel="noopener noreferrer">
                                    {t['tos.title']}
                                </Link>
                            </Typography>
                        )
                    }
                />
                <SignupButton variant="contained" color="primary" fullWidth type="submit" disabled={!agreeToTerms}>
                    {t.submitSignup}
                </SignupButton>
                <LoginLink variant="body2" align="center">
                    <Link to="/login">{t.loginLink}</Link>
                </LoginLink>
            </form>
        </StyledBox>
    );
};

export default Signup;