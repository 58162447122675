import React from 'react';
import { Box, Typography } from '@mui/material';
import LoginButton from './HeaderComponents/LoginButton';
import { useTranslations } from '../contexts/TranslationsContext';
import { styled } from '@mui/system';

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: theme.spacing(2),
  backgroundColor: '#A5D6A7',
  borderRadius: '8px',
  boxShadow: theme.shadows[2],
  maxWidth: 400,
  margin: 'auto',
  textAlign: 'center',
}));

const SuccessEmailVerification = ({ language }) => {
  const translations = useTranslations();
  return (
    <StyledBox>
      <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold', color: '#2E073F' }}>
        {translations.emailVerificationTitle}
      </Typography>
      <Typography variant="body2" sx={{ color: 'green', marginBottom: '16px' }}>
        {translations.emailVerificationDescription}
      </Typography>
      <LoginButton translations={translations} />
    </StyledBox>
  );
};

export default SuccessEmailVerification;