import React from 'react';
import { Container, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { useTranslations } from '../contexts/TranslationsContext';

const StyledContainer = styled(Container)(({ theme }) => ({
  backgroundColor: '#E3BBEB', // Lightest Purple for the background
  padding: theme.spacing(4),
  borderRadius: '8px',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  marginTop: theme.spacing(4),
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  color: '#4A0072', // Deep Purple for section titles
  marginBottom: theme.spacing(2),
}));

const SectionContent = styled(Typography)(({ theme }) => ({
  color: '#1A001D', // Darkest Purple for section content
  marginBottom: theme.spacing(4),
}));

const TermsOfService = () => {
  const translations = useTranslations();

  const renderContentWithNewLines = (content) => {
    if (!content) return null; // Check if content is defined
    return content.split('\n').map((line, index) => (
      <Typography key={index} variant="body1" gutterBottom>
        {line}
      </Typography>
    ));
  };

  return (
    <StyledContainer>
      <Typography variant="h3" gutterBottom sx={{ color: '#4A0072', fontWeight: 'bold', textAlign: 'center' }}>
        {translations['tos.title']}
      </Typography>
      <Typography variant="subtitle1" gutterBottom sx={{ color: '#9C44AA' }}>
        {translations['tos.last_updated']}
      </Typography>
      <SectionContent variant="body1">
        {renderContentWithNewLines(translations['tos.introduction'])}
      </SectionContent>
      <SectionTitle variant="h5">
        {translations['tos.section_1.title']}
      </SectionTitle>
      <SectionContent variant="body1">
        {renderContentWithNewLines(translations['tos.section_1.content'])}
      </SectionContent>
      <SectionTitle variant="h5">
        {translations['tos.section_2.title']}
      </SectionTitle>
      <SectionContent variant="body1">
        {renderContentWithNewLines(translations['tos.section_2.content'])}
      </SectionContent>
      <SectionTitle variant="h5">
        {translations['tos.section_3.title']}
      </SectionTitle>
      <SectionContent variant="body1">
        {renderContentWithNewLines(translations['tos.section_3.content'])}
      </SectionContent>
      <SectionTitle variant="h5">
        {translations['tos.section_4.title']}
      </SectionTitle>
      <SectionContent variant="body1">
        {renderContentWithNewLines(translations['tos.section_4.content'])}
      </SectionContent>
      <SectionTitle variant="h5">
        {translations['tos.section_5.title']}
      </SectionTitle>
      <SectionContent variant="body1">
        {renderContentWithNewLines(translations['tos.section_5.content'])}
      </SectionContent>
      <SectionTitle variant="h5">
        {translations['tos.section_6.title']}
      </SectionTitle>
      <SectionContent variant="body1">
        {renderContentWithNewLines(translations['tos.section_6.content'])}
      </SectionContent>
      <SectionTitle variant="h5">
        {translations['tos.section_7.title']}
      </SectionTitle>
      <SectionContent variant="body1">
        {renderContentWithNewLines(translations['tos.section_7.content'])}
      </SectionContent>
      <SectionTitle variant="h5">
        {translations['tos.section_8.title']}
      </SectionTitle>
      <SectionContent variant="body1">
        {renderContentWithNewLines(translations['tos.section_8.content'])}
      </SectionContent>
      <SectionTitle variant="h5">
        {translations['tos.section_9.title']}
      </SectionTitle>
      <SectionContent variant="body1">
        {renderContentWithNewLines(translations['tos.section_9.content'])}
      </SectionContent>
      <SectionTitle variant="h5">
        {translations['tos.section_10.title']}
      </SectionTitle>
      <SectionContent variant="body1">
        {renderContentWithNewLines(translations['tos.section_10.content'])}
      </SectionContent>
      <SectionTitle variant="h5">
        {translations['tos.section_11.title']}
      </SectionTitle>
      <SectionContent variant="body1">
        {renderContentWithNewLines(translations['tos.section_11.content'])}
      </SectionContent>
      <SectionTitle variant="h5">
        {translations['tos.section_12.title']}
      </SectionTitle>
      <SectionContent variant="body1">
        {renderContentWithNewLines(translations['tos.section_12.content'])}
      </SectionContent>
    </StyledContainer>
  );
};

export default TermsOfService;