import { styled } from '@mui/system';
import { Button, Box, MenuItem } from '@mui/material';
import TokenIcon from '@mui/icons-material/Token';

// Styled Components
export const HeaderDesktopNav = styled(Box)(({ theme }) => ({
    justifyContent: 'center',
    flexGrow: 1,
}));

export const HeaderNavItem = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
}));

export const HeaderUserOptions = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
}));

export const HeaderButton = styled(Button)(({ theme }) => ({
    color: '#E5A0FF', // Match button text color
    border: '2px solid transparent',
    transition: 'border-color 0.3s ease, color 0.3s ease',
    backgroundColor: '#4A0072', // Deep Purple for consistency
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', // Shadow for luxury effect
    margin: '0 5px',
    '&:hover, &.with-border': {
        fontWeight: 'bold', // Add bold font to buttons
        border: '2px solid #E5A0FF', // Consistent border color on hover
        borderRadius: '4px', // Adjust the border radius if needed
        backgroundColor: '#9C44AA', // Bright Purple on hover
    },
}));

export const StyledLoginButton = styled(Button)(({ theme }) => ({
    color: '#FFFFFF',
    backgroundColor: '#E5007D',
    border: '2px solid #FF4081',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', // Shadow for luxury effect
    transition: 'background-color 0.3s ease, border-color 0.3s ease', // Smooth transition
    margin: '0 5px',
    //margin: '0px 10px',
    '&:hover': {
        fontWeight: 'bold', // Add bold font to buttons
        backgroundColor: '#FF4081',
        border: '2px solid #E5007D',
    },
}));

export const StyledSignupButton = styled(Button)(({ theme }) => ({
    color: '#FFFFFF', // White text color
    backgroundColor: '#4CAF50', // Green background color
    border: '2px solid #388E3C', // Darker green border
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', // Shadow for luxury effect
    margin: '0 5px',
    //margin: '10px 10px', // Adjust margin as needed
    transition: 'background-color 0.3s ease, border-color 0.3s ease', // Smooth transition
    '&:hover': {
        fontWeight: 'bold', // Add bold font to buttons
        backgroundColor: '#45A049', // Darker green on hover
        border: '2px solid #4CAF50', // Lighter green border on hover
    },
}));

export const StyledLogoutButton = styled(Button)(({ theme }) => ({
    color: '#FFFFFF',
    backgroundColor: '#FF5722', // Orange background color
    border: '2px solid #E64A19', // Darker orange border
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', // Shadow for luxury effect
    //margin: '10px 10px', // Adjust margin as needed
    margin: '0 5px',
    transition: 'background-color 0.3s ease, border-color 0.3s ease', // Smooth transition
    '&:hover': {
        fontWeight: 'bold', // Add bold font to buttons
        backgroundColor: '#E64A19', // Darker orange on hover
        border: '2px solid #FF5722', // Lighter orange border on hover
    },
}));

export const UserInfo = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '10px',
    marginRight: '20px',
}));

export const UserName = styled('div')(({ theme }) => ({
    width: '40px',
    height: '40px',
    marginBottom: '10px',
    marginTop: '10px',
    borderRadius: '50%',
    backgroundColor: '#1A73E8', // Blue background for contrast
    color: '#FFFFFF', // White text color
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '1.1rem', // Adjusted font size for initials
    fontWeight: 'bold',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease, border-color 0.3s ease',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)', // Subtle shadow for depth
    '&:hover': {
        backgroundColor: '#155AB2', // Darker blue on hover
    },
}));

export const UserCredit = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    color: '#FFD700', // Gold color for text
    fontWeight: 'bold',
    fontSize: '1.1rem',
    padding: '5px 12px',
    border: '2px solid #FFD700', // Gold border
    borderRadius: '20px',
    backgroundColor: 'rgba(255, 215, 0, 0.2)', // Light purple background
    cursor: 'pointer',
    transition: 'background-color 0.3s ease, color 0.3s ease',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', // Shadow for luxury effect
    '&:hover': {
        color: '#FFFFFF', // White text for better contrast on hover
        backgroundColor: '#8A2BE2', // Rich purple background for hover
        border: '2px solid #FFD700', // Gold border
    },
}));

export const StyledTokenIcon = styled(TokenIcon)(({ theme }) => ({
    color: '#FFD700', // Gold color for token icon
    fontSize: '1.2rem', // Emphasis on icon size
}));

export const MenuItemStyled = styled(MenuItem)(({ theme }) => ({
    textAlign: 'center', // Center text in the menu item
    transition: 'border 0.3s, background-color 0.3s', // Add transition for smooth effect
    color: '#E5A0FF', // Match text color
    height: '48px', // Set a fixed height
    display: 'flex', // Use flexbox to center the text
    alignItems: 'center', // Center vertically
    justifyContent: 'center', // Center horizontally
    '&:hover': {
        border: '2px solid #E5A0FF', // Consistent hover border color
        backgroundColor: 'rgba(229, 160, 255, 0.2)', // Light background to match the theme
    },
}));

export const DrawerContent = styled(Box)(({ theme }) => ({
    width: '250px',
    backgroundColor: '#2E073F',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between', // Spacing elements vertically
    alignItems: 'center',
    padding: '1rem 0',
}));

export const DrawerButton = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    margin: '1rem 0',
}));

export const UserSection = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
}));

export const BottomButtons = styled(Box)(({ theme }) => ({
    marginBottom: '2rem', // Adding margin to push buttons upwards slightly
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    alignItems: 'center',
}));

export const StyledModalBox = styled(Box)(({ theme }) => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2),
    backgroundColor: '#ffffff',
    borderRadius: '8px',
    boxShadow: theme.shadows[2],
    maxWidth: 400,
    width: '100%', // Ensure the modal doesn't exceed the screen width
}));

export const ModalButtonContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    marginTop: theme.spacing(2),
    gap: theme.spacing(2), // Add spacing between buttons
}));
