import React from 'react';
import LoginIcon from '@mui/icons-material/Login';
import { useNavigate } from 'react-router-dom';

import {
    StyledLoginButton,
  } from './HeaderStyles';

const LoginButton = ({ translations, onClick }) => {
    const navigate = useNavigate();

    const handleClick = () => {
        if (onClick) {
            onClick();
        } else {
            navigate('/login');
        }
    };

    return (
        <StyledLoginButton 
            onClick={handleClick}
            startIcon={<LoginIcon />} // Add LoginIcon
        >
            {translations.login}
        </StyledLoginButton>
    );
};

export default LoginButton;