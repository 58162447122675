import React, { useState } from 'react';
import { Box, Typography, Button, Alert, TextField } from '@mui/material';
import { useTranslations } from '../contexts/TranslationsContext';
import { styled } from '@mui/system';

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: theme.spacing(2),
  backgroundColor: '#ffcccb', // Light pink background for error indication
  borderRadius: '8px',
  boxShadow: theme.shadows[2],
  maxWidth: 400,
  margin: 'auto',
  textAlign: 'center',
}));

const ResendButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  width: '80%', // Make the input field smaller
}));

const ResendEmailVerification = ({ language }) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const translations = useTranslations();
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [tokenError, setTokenError] = useState(false); // State to handle token error

  const handleResendVerificationEmail = async () => {
    try {
      const response = await fetch(`${apiUrl}/email/resend-verification-email`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      if (response.ok) {
        setMessage(translations.verificationEmailSent);
        setError('');
        setTokenError(false);
      } else if (response.status === 429) {
        setError(translations.tooManyRequests);
        setMessage('');
      }  else {
        const data = await response.json();
        if (data.detail === "Invalid token") {
          setTokenError(true);
          setError(translations.invalidTokenMessage);
        } else {
          setError(translations.verificationEmailFailed);
        }
        setMessage('');
      }
    } catch (error) {
      setError(translations.verificationEmailFailed);
      setMessage('');
    }
  };

  return (
    <StyledBox>
      <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold', color: '#2E073F' }}>
        {translations.invalidTokenTitle}
      </Typography>
      <Typography variant="body2" sx={{ color: 'red', marginBottom: '16px' }}>
        {translations.expiredTokenMessage}
      </Typography>
      <StyledTextField
        label={translations.email}
        variant="outlined"
        margin="normal"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      {message && <Alert severity="success">{message}</Alert>}
      {error && <Alert severity="error">{error}</Alert>}
      {tokenError && (
        <Typography variant="body2" color="error" sx={{ marginTop: '16px' }}>
          {translations.invalidTokenMessage}
        </Typography>
      )}
      <ResendButton variant="contained" color="secondary" onClick={handleResendVerificationEmail}>
        {translations.resendVerificationEmail}
      </ResendButton>
    </StyledBox>
  );
};

export default ResendEmailVerification;