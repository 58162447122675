// layouts/UserLayout.js
import React from 'react';
import BaseLayout from './BaseLayout';
import Header from '../components/Header';
import Footer from '../components/Footer';

const UserLayout = ({ children, language, setLanguage, isAuthenticated, logout }) => (
  <BaseLayout 
    Header={() => (
      <Header
        language={language}
        setLanguage={setLanguage}
        isAuthenticated={isAuthenticated}
        logout={logout}
      />
    )}
    Footer={() => <Footer language={language} />}
  >
    {children}
  </BaseLayout>
);

export default UserLayout;
