// layouts/AdminLayout.js
import React from 'react';
import BaseLayout from './BaseLayout';
import AdminHeader from '../components/AdminHeader';
import AdminFooter from '../components/Footer';

const AdminLayout = ({ children, language, setLanguage, isAuthenticated, logout }) => (
  <BaseLayout 
    Header={() => (
      <AdminHeader
        language={language}
        setLanguage={setLanguage}
        isAuthenticated={isAuthenticated}
        logout={logout}
      />
    )}
    Footer={() => <AdminFooter language={language} />}
  >
    {children}
  </BaseLayout>
);

export default AdminLayout;
