import React, { useState, useEffect } from 'react';
import { Menu } from '@mui/material';
import { HeaderButton, MenuItemStyled } from './HeaderStyles';
import { useNavigate } from 'react-router-dom';

const ServiceDropdown = ({ serviceButton, translations, language }) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const [services, setServices] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const navigate = useNavigate();
    
    useEffect(() => {
        // Fetch the service information from the backend API
        fetch(`${apiUrl}/data/services`)
          .then(response => {
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            return response.json();
          })
          .then(data => {
            if (data) {
              setServices(data.services);
            }
          })
          .catch(error => console.error('Error fetching service price:', error));
      }, [apiUrl]);

    const handleMenuToggle = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget); // Toggle open/close on button click
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleMenuItemClick = (url) => {
        navigate(url); // Navigate to the service URL
    };

    const isMenuOpen = Boolean(anchorEl);

    return (
        <div>
            <HeaderButton
                onClick={handleMenuToggle} // Toggle the menu on click
                ref={serviceButton}
                className={isMenuOpen ? 'with-border' : ''} // Apply the border class conditionally
            >
                {translations.service}
            </HeaderButton>
            <Menu
                anchorEl={anchorEl}
                open={isMenuOpen}
                onClose={handleMenuClose}
                MenuListProps={{
                    onClick: handleMenuClose, // Close the menu when an item is clicked
                }}
                sx={{
                    '& .MuiPaper-root': {
                        backgroundColor: '#4A0072',
                        color: '#E3BBEB',
                    },
                }}
            >
                {/* Loop through services and display the 'name' (title) of each */}
                {services.map((service) => (
                    <MenuItemStyled
                        key={service.id}
                        onClick={() => handleMenuItemClick(service.url)} // Add onClick handler
                    >
                        {service.title[language]}
                    </MenuItemStyled>
                ))}
            </Menu>
        </div>
    );
};

export default ServiceDropdown;