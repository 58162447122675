import React, { useState, useEffect, useContext } from 'react';
import { Box, Typography, CircularProgress, Alert } from '@mui/material';
import { styled } from '@mui/system';
import { AuthContext } from '../contexts/AuthContext';
import { UserContext } from '../contexts/UserContext'; // Import the UserContext
import { useTranslations } from '../contexts/TranslationsContext';
import { ProcessButton, UploadImageFrame, DownloadImageFrame, ModalWindow } from './ReusableComponents'; // Import necessary components

const Container = styled(Box)(({ theme }) => ({
  textAlign: 'center',
  padding: '20px',
}));

const ButtonContainer = styled(Box)(({ theme }) => ({
  marginTop: '20px',
  display: 'flex',
  justifyContent: 'center',
  gap: '20px',
}));

const MessageBox = styled(Box)(({ theme }) => ({
  margin: '10px auto',
  textAlign: 'center',
  maxWidth: '500px',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
}));

const RemoveBackgroundAI = ({ language }) => {
  const t = useTranslations();
  const apiUrl = process.env.REACT_APP_API_URL;
  const { isAuthenticated } = useContext(AuthContext);
  const { setUser, loading: userLoading } = useContext(UserContext); // Access user data from context
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [resultImage, setResultImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [service, setService] = useState(null);

  useEffect(() => {
    // Fetch the service information from the backend API
    fetch(`${apiUrl}/data/services`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        const this_service = data.services.find(service => service.url === '/remove-background-ai');
        if (this_service) {
          setService(this_service);
        }
      })
      .catch(error => console.error('Error fetching service price:', error));
  }, [apiUrl]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    setPreviewImage(URL.createObjectURL(file));
    setErrorMessage('');
  };

  const handleUpload = async () => {
    if (!selectedFile) return;

    setLoading(true);
    setErrorMessage('');
    const formData = new FormData();
    formData.append('file', selectedFile);

    const token = isAuthenticated ? localStorage.getItem('token') : localStorage.getItem('guestToken');

    try {
      const response = await fetch(`${apiUrl}/ai/remove_background`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
        body: formData,
      });

      if (response.ok) {
        const blob = await response.blob();
        const url = URL.createObjectURL(blob);
        setResultImage(url);
        setSuccessMessage(t.processSuccess);
        setErrorMessage('');
      } else {
        const data = await response.json();
        switch (response.status) {
          case 413:
            setErrorMessage(t.errorExceededSize);
            break;
          case 401:
            setErrorMessage(t.notEnoughTokens);
            break;
          case 415:
            setErrorMessage(t.errorFileType);
            break;
          case 500:
            setErrorMessage(t.errorProcessingImage);
            break;
          default:
            setErrorMessage(data.detail || t.processFailed);
        }
        setSuccessMessage('');
      }
    } catch (error) {
      setErrorMessage(t.generalError);
      setSuccessMessage('');
    } finally {
      setLoading(false);
    }
  };

  const handleConfirmTransaction = () => {
    setModalOpen(false);
    handleUpload();
  };

  const handleRemoveBackgroundClick = () => {
    setModalOpen(true);
  };

  const fetchUserData = async () => {
    try {
      const url = isAuthenticated ? `${apiUrl}/transactions/user` : `${apiUrl}/transactions/guest_user`;
      const currentToken = isAuthenticated ? localStorage.getItem('token') : localStorage.getItem('guestToken');
      const response = await fetch(url, {
        headers: {
          'Authorization': `Bearer ${currentToken}`,
        },
      });
      if (response.ok) {
        const data = await response.json();
        setUser(data);
      } else {
        console.error('Failed to fetch user data');
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  const handleDownloadClick = async () => {
    try {
      await fetchUserData();

      // Create a temporary link element
      const link = document.createElement('a');
      link.href = resultImage;
      link.download = 'processed-image.png';

      // Append the link to the document body and click it to trigger the download
      document.body.appendChild(link);
      link.click();

      // Remove the link from the document
      document.body.removeChild(link);
      setErrorMessage('');
      setSuccessMessage(t.successDownload);

    } catch {
      setErrorMessage(t.generalError);
    }
  };

  if (userLoading) {
    return <CircularProgress />;
  }

  return (
    <Container>
      <Typography variant="h4" gutterBottom>{service?.title?.[language]}</Typography>
      <Typography variant="body1" gutterBottom>{service?.description?.[language]}</Typography>
      {errorMessage && (
        <MessageBox>
          <Alert severity="error" sx={{ fontSize: '0.875rem' }}>{errorMessage}</Alert>
        </MessageBox>
      )}
      {successMessage && (
        <MessageBox>
          <Alert severity="success" sx={{ fontSize: '0.875rem' }}>{successMessage}</Alert>
        </MessageBox>
      )}
      <ButtonContainer>
        <ProcessButton
          onClick={handleRemoveBackgroundClick}
          disabled={!selectedFile || loading}
          label={t.processButton}
          loading={loading}
        />
      </ButtonContainer>
      <Box sx={{ display: 'flex', justifyContent: 'center', gap: '20px', flexWrap: 'wrap', marginTop: '20px' }}>
        
        <UploadImageFrame
          previewImage={previewImage}
          translations={t}
          handleFileChange={handleFileChange}
        />
        
        <DownloadImageFrame
          previewImage={previewImage}
          resultImage={resultImage}
          loading={loading}
          translations={t}
          onDownloadClick={handleDownloadClick}
        />
      </Box>
      <ModalWindow
        open={modalOpen}
        handleClose={() => setModalOpen(false)}
        handleConfirm={handleConfirmTransaction}
        title={t.confirmTitle}
        description={t.confirmTransactionDescription ? t.confirmTransactionDescription.replace('{price}', service?.price) : ''}
        confirmText={t.confirmText}
        cancelText={t.cancelText}
      />
    </Container>
  );
};

export default RemoveBackgroundAI;