import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const VerifyEmail = () => {
    const { token } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        const verifyToken = async () => {
            try {
                const response = await fetch(`/verify/${token}`);
                if (response.ok) {
                    // Redirect on successful verification
                    navigate('/');
                } else if (response.status === 400) {
                    // Redirect to resend token page if expired
                    navigate('/resend-token');
                }
            } catch (error) {
                console.error("Verification error:", error);
                navigate('/resend-token');
            }
        };
        verifyToken();
    }, [token, navigate]);

    return <div>Verifying your email...</div>;
};

export default VerifyEmail;
