import React from 'react';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

import {
    StyledLogoutButton,
  } from './HeaderStyles';

const LogoutButton = ({ onClick, translations }) => {
    return (
        <StyledLogoutButton 
            onClick={onClick}
            startIcon={<ExitToAppIcon />}
        >   
            {translations.logout}
        </StyledLogoutButton>
    );
};

export default LogoutButton;

