import React, { useState } from 'react';
import { Box, Typography, Button, Alert, TextField } from '@mui/material';
import { useTranslations } from '../contexts/TranslationsContext';
import { styled } from '@mui/system';

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: theme.spacing(2),
  backgroundColor: '#ffffff',
  borderRadius: '8px',
  boxShadow: theme.shadows[2],
  maxWidth: 400,
  margin: 'auto',
  textAlign: 'center',
}));

const SendButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  width: '80%', // Make the input field smaller
}));

const ForgotPassword = ({ language }) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const translations = useTranslations();
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [emailError, setEmailError] = useState('');

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSendVerificationEmail = async () => {
    if (!validateEmail(email)) {
      setEmailError(translations.emailInvalid);
      return;
    } else {
      setEmailError('');
    }

    try {
      const response = await fetch(`${apiUrl}/email/password/forgot`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      if (response.ok) {
        setMessage(translations.verificationEmailSent);
        setError('');
      } else if (response.status === 429) {
        setError(translations.tooManyRequests);
        setMessage('');
      } else {
        setError(translations.verificationEmailFailed);
        setMessage('');
      }
    } catch (error) {
      setError(translations.verificationEmailFailed);
      setMessage('');
    }
  };

  return (
    <StyledBox>
      <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold', color: '#2E073F' }}>
        {translations.forgotPasswordTitle}
      </Typography>
      <Typography variant="body2" sx={{ color: '#2E073F', marginBottom: '16px' }}>
        {translations.forgotPasswordDescription}
      </Typography>
      <StyledTextField
        label={translations.email}
        variant="outlined"
        margin="normal"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        error={!!emailError}
        helperText={emailError}
      />
      {message && <Alert severity="success">{message}</Alert>}
      {error && <Alert severity="error">{error}</Alert>}
      <SendButton variant="contained" color="primary" onClick={handleSendVerificationEmail}>
        {translations.sendVerificationEmail}
      </SendButton>
    </StyledBox>
  );
};

export default ForgotPassword;