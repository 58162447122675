// layouts/BaseLayout.js
import React from 'react';

const BaseLayout = ({ Header, Footer, children }) => (
  <>
    <Header />
    <main style={{ padding: '20px', backgroundColor: '#F5F5F5' }}>
      {children}
    </main>
    <Footer />
  </>
);

export default BaseLayout;