import React, { createContext, useState, useEffect, useCallback, useRef } from 'react';
import { jwtDecode } from 'jwt-decode';
import { getFingerprint } from '../utils/fingerprint';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [token, setToken] = useState(localStorage.getItem('token'));
  const [guestToken, setGuestToken] = useState(localStorage.getItem('guestToken'));
  const [isAuthenticated, setIsAuthenticated] = useState(!!token);
  const [language, setLanguage] = useState('jp');
  const initialVerificationDone = useRef(false);
  const [isAdmin, setIsAdmin] = useState(false);  // Add isAdmin state
  const [loading, setLoading] = useState(true); // New loading state to control access restrictions
    

  // Initialize guest session and set guest token
  const initializeGuestSession = useCallback(async () => {
    const fingerprint = await getFingerprint();
    try {
      const response = await fetch(`${apiUrl}/verify/guest_token`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ fingerprint }),
      });

      if (response.ok) {
        const data = await response.json();
        // Decode token to check role
        const decodedToken = jwtDecode(data.access_token);
        localStorage.setItem('guestToken', data.access_token);
        setGuestToken(data.access_token);
        setLanguage(decodedToken.language);
      } else {
        console.error('Failed to initialize guest session');
      }
    } catch (error) {
      console.error('Error initializing guest session:', error);
    }
  }, [apiUrl]);

  
  // Check if guest token is valid, or reinitialize it
  const verifyGuestToken = useCallback(async () => {
    if (!guestToken) {
      await initializeGuestSession();
      return;
    }

    try {
      const response = await fetch(`${apiUrl}/verify/guest_token`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${guestToken}`,
        },
      });

      if (!response.ok) {
        // Guest token invalid, clear it and reinitialize
        localStorage.removeItem('guestToken');
        setGuestToken(null);
        await initializeGuestSession();
      }
    } catch (error) {
      console.error('Error verifying guest token:', error);
    }
  }, [apiUrl, guestToken, initializeGuestSession]);

  // Verify user token on app load
  useEffect(() => {
    const verifyToken = async () => {
      if (token) {
        try {
          const response = await fetch(`${apiUrl}/verify/token`, {
            method: 'POST',
            headers: {
              'Authorization': `Bearer ${token}`,
            },
          });

          if (response.ok) {
            const data = await response.json();
            // Decode token to check role
            const decodedToken = jwtDecode(data.access_token);
            localStorage.setItem('token', data.access_token);
            setIsAdmin(decodedToken.role === 'admin'); // Set isAdmin based on role
            setIsAuthenticated(true);
            setLanguage(decodedToken.language);
          } else {
            setIsAuthenticated(false);
            setIsAdmin(false);  // Reset isAdmin state
            localStorage.removeItem('token');
            setToken(null);
          }
        } catch (error) {
          setIsAuthenticated(false);
          setIsAdmin(false);  // Reset isAdmin state
          localStorage.removeItem('token');
          setToken(null);
        }
      } else {
        await verifyGuestToken();
      }
      setLoading(false); // Mark loading as complete after initial verification
      initialVerificationDone.current = true;
    };

    if (!initialVerificationDone.current) {
      verifyToken();
    }
  }, [token, apiUrl, verifyGuestToken]);

  // Handle user login
  const login = (newToken) => {
    const decodedToken = jwtDecode(newToken);
    setToken(newToken);
    setIsAuthenticated(true);
    setIsAdmin(decodedToken.role === 'admin'); // Set isAdmin based on role
    setLanguage(decodedToken.language);
    localStorage.setItem('token', newToken);
    localStorage.removeItem('guestToken'); // Clear guest session if user logs in
    setGuestToken(null);
  };

  // Handle user logout
  const logout = () => {
    setToken(null);
    setIsAuthenticated(false);
    setIsAdmin(false);
    localStorage.removeItem('token');
    localStorage.removeItem('guestToken');
    setGuestToken(null);
    initializeGuestSession();
  };

  return (
    <AuthContext.Provider value={{ token, guestToken, isAdmin, isAuthenticated, login, logout, language, setLanguage, loading }}>
      {children}
    </AuthContext.Provider>
  );
};