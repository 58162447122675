import React from 'react';
import { Button, Drawer, List } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import LanguageSelector from './LanguageSelector';
import ServiceDropdown from './ServiceDropdown';
import UserDropdown from './UserDropdown';
import LoginButton from './LoginButton';
import SignupButton from './SignupButton';
import LogoutButton from './LogoutButton'; // Import the new LogoutButton component
import {
    UserName,
    UserCredit,
    StyledTokenIcon,
    DrawerContent,
    DrawerButton,
    UserSection,
    BottomButtons,
  } from './HeaderStyles';

// Main Component
const MobileSideBar = ({ drawerOpen, toggleDrawer, isAuthenticated, user, language, translations, handleLogout, handlePopoverOpen, menuAnchorEl, menuOpen, handleMenuClose, setLanguage }) => {
    const navigate = useNavigate();

    const getInitial = (fullName) => {
        if (!fullName || fullName.startsWith('Guest')) return 'G';
        return fullName.charAt(0).toUpperCase();
    };

    const handleButtonClick = (path) => {
        navigate(path);
        toggleDrawer(false)();
    };

    return (
        <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
            <DrawerContent>
                <List>
                    <DrawerButton>
                        <Button 
                            sx={{
                                color: '#E5A0FF',
                                border: '2px solid transparent',
                                transition: 'border-color 0.3s ease, color 0.3s ease',
                                backgroundColor: '#4A0072',
                                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                                margin: '0 8px',
                                '&:hover, &.with-border': {
                                    fontWeight: 'bold',
                                    border: '2px solid #E5A0FF',
                                    borderRadius: '4px',
                                    backgroundColor: '#9C44AA',
                                },
                            }}
                            onClick={() => handleButtonClick('/')}
                        >
                            {translations.home}
                        </Button>
                    </DrawerButton>

                    <DrawerButton>
                        <ServiceDropdown 
                            serviceButton={null} 
                            translations={translations} 
                            language={language} 
                        />
                    </DrawerButton>
                </List>

                <UserSection>
                    {isAuthenticated ? (
                        <>
                            <UserDropdown 
                                user={user} 
                                translations={translations} 
                                handleLogout={handleLogout} 
                                onMenuClose={toggleDrawer(false)} // Pass the toggleDrawer function
                            />

                            <UserCredit onClick={(event) => handlePopoverOpen(event, `${translations.credit}${user?.credit}`)}>
                                <StyledTokenIcon />
                                {user?.credit}
                            </UserCredit>
                        </>
                    ) : (
                        <>
                            <UserName onClick={(event) => handlePopoverOpen(event, user?.full_name)}>
                                {getInitial(user?.full_name)}
                            </UserName>

                            <UserCredit onClick={(event) => handlePopoverOpen(event, `${translations.credit}${user?.credit}`)}>
                                <StyledTokenIcon />
                                {user?.credit}
                            </UserCredit>
                        </>
                    )}
                </UserSection>

                <BottomButtons>
                    {!isAuthenticated && (
                        <>
                            <LoginButton translations={translations} language={language} onClick={() => handleButtonClick('/login')} /> 
                            <SignupButton translations={translations} language={language} onClick={() => handleButtonClick('/signup')} />
                        </>
                    )}
                    {isAuthenticated && (
                        <LogoutButton onClick={handleLogout} translations={translations} language={language} />
                    )}
                    <LanguageSelector 
                        translations={translations} 
                        language={language} 
                        setLanguage={setLanguage} 
                        onLanguageChange={toggleDrawer(false)} // Pass the toggleDrawer function
                    />
                </BottomButtons>
            </DrawerContent>
        </Drawer>
    );
};

export default MobileSideBar;