import React from 'react';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { useNavigate } from 'react-router-dom';
import {
    StyledSignupButton,
  } from './HeaderStyles';

const SignupButton = ({ translations, onClick }) => {
    const navigate = useNavigate();

    const handleClick = () => {
        if (onClick) {
            onClick();
        } else {
            navigate('/signup');
        }
    };

    return (
        <StyledSignupButton 
            onClick={handleClick}
            startIcon={<PersonAddIcon />} // Add PersonAddIcon
        >
            {translations.signup}
        </StyledSignupButton>
    );
};

export default SignupButton;