import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Paper, Button, IconButton, Dialog, DialogActions, DialogContent, DialogTitle,
  TextField, Tooltip, Alert, TableSortLabel
} from '@mui/material';
import { Edit, Delete, Add } from '@mui/icons-material';
import { ModalWindow } from '../ReusableComponents';

const ServiceManagement = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [services, setServices] = useState([]);
  const [editService, setEditService] = useState(null);
  const [deleteServiceId, setDeleteServiceId] = useState(null);
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [isAddModalOpen, setAddModalOpen] = useState(false);
  const [isAddConfirmModalOpen, setAddConfirmModalOpen] = useState(false);
  const [isEditConfirmModalOpen, setEditConfirmModalOpen] = useState(false);
  const [newService, setNewService] = useState({
    service_name: '', title_key: '', description_key: '', title_ja: '',
    description_ja: '', title_en: '', description_en: '', rating: 0,
    use_count: 0, price: 0, url: '', created_at: '', updated_at: ''
  });
  const [validationErrors, setValidationErrors] = useState({});
  const [alertMessage, setAlertMessage] = useState(null);
  const [alertSeverity, setAlertSeverity] = useState('success');
  const [searchQuery, setSearchQuery] = useState('');
  const [sortConfig, setSortConfig] = useState({ sortBy: 'service_name', sortOrder: 'asc' });

  const getToken = () => localStorage.getItem('token');

  const fetchServices = useCallback((search = '', sortBy = 'service_name', sortOrder = 'asc') => {
    const token = getToken();
    axios.get(`${apiUrl}/data/services_admin/`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: { search, sort_by: sortBy, sort_order: sortOrder }
    })
    .then(response => setServices(response.data.services))
    .catch(error => console.error("Error fetching services:", error));
  }, [apiUrl]);

  useEffect(() => {
    fetchServices();
  }, [apiUrl, fetchServices]);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    fetchServices(e.target.value, sortConfig.sortBy, sortConfig.sortOrder);
  };

  const handleSort = (column) => {
    const isAsc = sortConfig.sortBy === column && sortConfig.sortOrder === 'asc';
    const newSortOrder = isAsc ? 'desc' : 'asc';
    setSortConfig({ sortBy: column, sortOrder: newSortOrder });
    fetchServices(searchQuery, column, newSortOrder);
  };

  const handleEdit = (service) => {
    setEditService(service);
    setEditModalOpen(true);
  };

  const handleEditChange = (e) => {
    setEditService({ ...editService, [e.target.name]: e.target.value });
  };

  const validateService = (service) => {
    const errors = {};
    const urlPattern = /^\/[a-zA-Z0-9-]+(\/[a-zA-Z0-9-]+)*$/;

    if (!service.service_name) errors.service_name = 'Service name is required';
    if (!service.title_key) errors.title_key = 'Title key is required';
    if (!service.description_key) errors.description_key = 'Description key is required';
    if (service.title_key === service.description_key) errors.description_key = 'Title key and description key cannot be the same';
    if (!urlPattern.test(service.url)) errors.url = 'URL must match /something or /something/something';
    if (!Number.isInteger(Number(service.price))) errors.price = 'Price must be an integer';

    return errors;
  };

  const confirmEdit = () => {
    const errors = validateService(editService);
    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      return;
    }
    setValidationErrors({});
    setEditConfirmModalOpen(true);
  };

  const handleConfirmEdit = () => {
    const token = getToken();
  
    const formattedEditService = {
      service_name: editService.service_name,
      title_key: editService.title_key,
      title_value: {
        en: editService.title_en,
        jp: editService.title_ja,
      },
      description_key: editService.description_key,
      description_value: {
        en: editService.description_en,
        jp: editService.description_ja,
      },
      price: editService.price,
      url: editService.url,
    };

    axios.put(`${apiUrl}/data/services/${editService.id}`, formattedEditService, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(() => {
        setServices((prevServices) =>
          prevServices.map((service) =>
            service.id === editService.id ? { ...service, ...editService } : service
          )
        );
        setEditModalOpen(false);
        setEditConfirmModalOpen(false);
        setAlertMessage('Service updated successfully');
        setAlertSeverity('success');
      })
      .catch((error) => {
        console.error("Error updating service:", error);
        setAlertMessage('Error updating service');
        setAlertSeverity('error');
      });
  };

  const confirmDelete = () => {
    const token = getToken();

    axios.delete(`${apiUrl}/data/services`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: { service_ids: [deleteServiceId] }
    })
      .then(() => {
        setServices((prevServices) => prevServices.filter(service => service.id !== deleteServiceId));
        setDeleteModalOpen(false);
        setAlertMessage('Service deleted successfully');
        setAlertSeverity('success');
      })
      .catch(error => {
        console.error("Error deleting service:", error);
        setAlertMessage('Error deleting service');
        setAlertSeverity('error');
      });
  };

  const handleAddService = () => {
    const errors = validateService(newService);
    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      return;
    }
    setValidationErrors({});
    setAddConfirmModalOpen(true);
  };

  const handleConfirmAddService = () => {
    const token = getToken();
  
    const formattedNewService = [{
      service_name: newService.service_name,
      title_key: newService.title_key,
      title_value: {
        en: newService.title_en,
        jp: newService.title_ja,
      },
      description_key: newService.description_key,
      description_value: {
        en: newService.description_en,
        jp: newService.description_ja,
      },
      price: newService.price,
      url: newService.url,
    }];

    axios.post(`${apiUrl}/data/services`, formattedNewService, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    })
      .then(response => {
        setServices(prevServices => [
          ...prevServices,
          {
            id: response.data.id,  // Assuming the response contains the new service's ID
            service_name: newService.service_name,
            title_key: newService.title_key,
            description_key: newService.description_key,
            title_ja: newService.title_ja,
            description_ja: newService.description_ja,
            title_en: newService.title_en,
            description_en: newService.description_en,
            rating: newService.rating,
            use_count: newService.use_count,
            price: newService.price,
            url: newService.url,
            created_at: newService.created_at,
            updated_at: newService.updated_at
          }
        ]);
        setAddModalOpen(false);
        setAddConfirmModalOpen(false);
        setNewService({ service_name: '', title_key: '', description_key: '', title_ja: '',
          description_ja: '', title_en: '', description_en: '', rating: 0,
          use_count: 0, price: 0, url: '', created_at: '', updated_at: '' });
        setAlertMessage('Service added successfully');
        setAlertSeverity('success');
      })
      .catch(error => {
        console.error("Error adding service:", error);
        setAlertMessage('Error adding service');
        setAlertSeverity('error');
      });
  };

  return (
    <div style={{ padding: '2rem' }}>
      <h1>Service Management Panel</h1>
      {alertMessage && <Alert style={{ marginBottom: '10px' }} severity={alertSeverity}>{alertMessage}</Alert>}
      <TextField
        label="Search"
        value={searchQuery}
        onChange={handleSearchChange}
        fullWidth
        style={{ marginBottom: '1rem' }}
      />
      <Button variant="contained" startIcon={<Add />} onClick={() => setAddModalOpen(true)}>
        Add Service
      </Button>

      <TableContainer component={Paper} style={{ marginTop: '1rem', width: '100%', overflowX: 'auto' }}>
        <Table style={{ width: '2000px' }}>
          <TableHead>
            <TableRow>
              <TableCell style={{ width: '50px' }}>No.</TableCell>
              <TableCell>ID</TableCell>
              <TableCell style={{ width: '200px' }}>
                <TableSortLabel
                  active={sortConfig.sortBy === 'service_name'}
                  direction={sortConfig.sortOrder}
                  onClick={() => handleSort('service_name')}
                >
                  Service Name
                </TableSortLabel>
              </TableCell>
              <TableCell style={{ width: '200px' }}>
                <TableSortLabel
                  active={sortConfig.sortBy === 'title_key'}
                  direction={sortConfig.sortOrder}
                  onClick={() => handleSort('title_key')}
                >
                  Title Key
                </TableSortLabel>
              </TableCell>
              <TableCell style={{ width: '200px' }}>
                <TableSortLabel
                  active={sortConfig.sortBy === 'description_key'}
                  direction={sortConfig.sortOrder}
                  onClick={() => handleSort('description_key')}
                >
                  Description Key
                </TableSortLabel>
              </TableCell>
              <TableCell style={{ width: '200px' }}>Title (JA)</TableCell>
              <TableCell style={{ width: '500px' }}>Description (JA)</TableCell>
              <TableCell style={{ width: '200px' }}>Title (EN)</TableCell>
              <TableCell style={{ width: '500px' }}>Description (EN)</TableCell>
              <TableCell style={{ width: '200px' }}>
                <TableSortLabel
                  active={sortConfig.sortBy === 'rating'}
                  direction={sortConfig.sortOrder}
                  onClick={() => handleSort('rating')}
                >
                  Rating
                </TableSortLabel>
              </TableCell>
              <TableCell style={{ width: '50px' }}>
                <TableSortLabel
                  active={sortConfig.sortBy === 'use_count'}
                  direction={sortConfig.sortOrder}
                  onClick={() => handleSort('use_count')}
                >
                  Use Count
                </TableSortLabel>
              </TableCell>
              <TableCell style={{ width: '50px' }}>
                <TableSortLabel
                  active={sortConfig.sortBy === 'price'}
                  direction={sortConfig.sortOrder}
                  onClick={() => handleSort('price')}
                >
                  Price
                </TableSortLabel>
              </TableCell>
              <TableCell style={{ width: '200px' }}>URL</TableCell>
              <TableCell style={{ width: '200px' }}>
                <TableSortLabel
                  active={sortConfig.sortBy === 'created_at'}
                  direction={sortConfig.sortOrder}
                  onClick={() => handleSort('created_at')}
                >
                  Created At
                </TableSortLabel>
              </TableCell>
              <TableCell style={{ width: '200px' }}>
                <TableSortLabel
                  active={sortConfig.sortBy === 'updated_at'}
                  direction={sortConfig.sortOrder}
                  onClick={() => handleSort('updated_at')}
                >
                  Updated At
                </TableSortLabel>
              </TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {services.map((service, index) => (
              <TableRow key={service.id}>
                <TableCell style={{ width: '50px' }}>{index + 1}</TableCell>
                <TableCell style={{ width: '200px' }}>{service.id}</TableCell>
                <TableCell style={{ width: '200px' }}>{service.service_name}</TableCell>
                <TableCell style={{ width: '200px' }}>{service.title_key}</TableCell>
                <TableCell style={{ width: '200px' }}>{service.description_key}</TableCell>
                <TableCell style={{ width: '200px' }}>{service.title_ja}</TableCell>
                <TableCell style={{ width: '500px' }}>{service.description_ja}</TableCell>
                <TableCell style={{ width: '200px' }}>{service.title_en}</TableCell>
                <TableCell style={{ width: '500px' }}>{service.description_en}</TableCell>
                <TableCell style={{ width: '200px' }}>{service.rating}</TableCell>
                <TableCell style={{ width: '50px' }}>{service.use_count}</TableCell>
                <TableCell style={{ width: '50px' }}>{service.price}</TableCell>
                <TableCell style={{ width: '200px' }}>{service.url}</TableCell>
                <TableCell style={{ width: '200px' }}>{new Date(service.created_at).toLocaleString()}</TableCell>
                <TableCell style={{ width: '200px' }}>{new Date(service.updated_at).toLocaleString()}</TableCell>
                <TableCell>
                  <Tooltip title="Edit">
                    <IconButton onClick={() => handleEdit(service)}><Edit /></IconButton>
                  </Tooltip>
                  <Tooltip title="Delete">
                    <IconButton onClick={() => { setDeleteServiceId(service.id); setDeleteModalOpen(true); }}><Delete /></IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Edit Modal */}
      <Dialog open={isEditModalOpen} onClose={() => setEditModalOpen(false)}>
        <DialogTitle>Edit Service</DialogTitle>
        <DialogContent>
          {['service_name', 'title_ja', 'description_ja', 'title_en', 'description_en', 'price'].map(field => (
            <TextField
              key={field}
              margin="dense"
              label={field}
              name={field}
              value={editService?.[field] || ''}
              onChange={handleEditChange}
              fullWidth
              multiline={['description_ja', 'description_en'].includes(field)}
              rows={['description_ja', 'description_en'].includes(field) ? 4 : 1} // Allow multiple lines for descriptions
              disabled={['id', 'title_key', 'description_key', 'url'].includes(field)}
              error={!!validationErrors[field]}
              helperText={validationErrors[field]}
            />
          ))}
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" onClick={confirmEdit}>
            Save Changes
          </Button>
          <Button variant="outlined" color="secondary" onClick={() => setEditModalOpen(false)}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete Confirmation Modal */}
      <ModalWindow
        open={isDeleteModalOpen}
        handleClose={() => setDeleteModalOpen(false)}
        handleConfirm={confirmDelete}
        title="Confirm Deletion"
        description="Are you sure you want to delete this service?"
        confirmText="Delete"
        cancelText="Cancel"
      />

      {/* Add Service Modal */}
      <Dialog open={isAddModalOpen} onClose={() => setAddModalOpen(false)}>
        <DialogTitle>Add Service</DialogTitle>
        <DialogContent>
          {['service_name', 'title_key', 'description_key', 'title_ja', 'description_ja', 'title_en', 'description_en', 'price', 'url'].map(field => (
            <TextField
              key={field}
              margin="dense"
              label={field}
              name={field}
              value={newService[field]}
              onChange={(e) => setNewService({ ...newService, [e.target.name]: e.target.value })}
              fullWidth
              multiline={['description_ja', 'description_en'].includes(field)}
              rows={['description_ja', 'description_en'].includes(field) ? 4 : 1} // Allow multiple lines for descriptions
              error={!!validationErrors[field]}
              helperText={validationErrors[field]}
            />
          ))}
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" onClick={handleAddService}>
            Add Service
          </Button>
          <Button variant="outlined" color="secondary" onClick={() => setAddModalOpen(false)}>
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* Add Confirmation Modal */}
      <ModalWindow
        open={isAddConfirmModalOpen}
        handleClose={() => setAddConfirmModalOpen(false)}
        handleConfirm={handleConfirmAddService}
        title="Confirm Addition"
        description="Are you sure you want to add this service?"
        confirmText="Add"
        cancelText="Cancel"
      />

      {/* Edit Confirmation Modal */}
      <ModalWindow
        open={isEditConfirmModalOpen}
        handleClose={() => setEditConfirmModalOpen(false)}
        handleConfirm={handleConfirmEdit}
        title="Confirm Changes"
        description="Are you sure you want to save changes to this service?"
        confirmText="Save"
        cancelText="Cancel"
      />
    </div>
  );
};

export default ServiceManagement;