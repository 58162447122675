import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
    HeaderDesktopNav,
    HeaderNavItem,
    HeaderButton,
  } from '../HeaderComponents/HeaderStyles';


const DesktopNav = ({ isAuthenticated, user, translations, handleLogout, handlePopoverOpen, serviceButton, language, setLanguage}) => {
    const navigate = useNavigate();

    return (
        <>
            {/* Desktop Navigation */}
            <HeaderDesktopNav sx={{ display: { md: 'flex' }}}>
                <HeaderNavItem>
                    <HeaderButton onClick={() => navigate(`/admin_panel/`)}>
                        {translations.websiteAccessLog}
                    </HeaderButton>
                    <HeaderButton onClick={() => navigate(`/admin_panel/service-management`)}>
                        {translations.serviceManagement}
                    </HeaderButton>

                    <HeaderButton onClick={() => navigate(`/admin_panel/translation-management`)}>
                        {translations.translationManagement}
                    </HeaderButton>

                    <HeaderButton onClick={() => navigate(`/admin_panel/transaction-management`)}>
                        {translations.transactionManagement}
                    </HeaderButton>

                    <HeaderButton onClick={() => navigate(`/admin_panel/user-management`)}>
                        {translations.userManagement}
                    </HeaderButton>

                    <HeaderButton onClick={() => navigate(`/admin_panel/guest-user-management`)}>
                        {translations.guestUserManagement}
                    </HeaderButton>

                </HeaderNavItem>
                
            </HeaderDesktopNav>
        </>
    );
};

export default DesktopNav;