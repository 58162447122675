import React from 'react';
import LanguageSelector from './LanguageSelector';
import ServiceDropdown from './ServiceDropdown';
import UserDropdown from './UserDropdown';
import LoginButton from './LoginButton';
import SignupButton from './SignupButton';
import LogoutButton from './LogoutButton';
import { useNavigate } from 'react-router-dom';
import {
    HeaderDesktopNav,
    HeaderNavItem,
    HeaderUserOptions,
    HeaderButton,
    UserInfo,
    UserName,
    UserCredit,
    StyledTokenIcon,
  } from './HeaderStyles';


const DesktopNav = ({ isAuthenticated, user, translations, handleLogout, handlePopoverOpen, serviceButton, language, setLanguage}) => {
    const navigate = useNavigate();

    const getInitial = (fullName) => {
        if (!fullName || fullName.startsWith('Guest')) return 'G'; // Default for guest users
        return fullName.charAt(0).toUpperCase();
    };

    return (
        <>
            {/* Desktop Navigation */}
            <HeaderDesktopNav sx={{ display: { xs: 'none', md: 'flex' }}}>
                <HeaderNavItem>
                    <HeaderButton onClick={() => navigate('/')}>
                        {translations.home}
                    </HeaderButton>

                    <ServiceDropdown 
                        serviceButton={serviceButton} 
                        translations={translations}
                        language={language}
                    />
                </HeaderNavItem>
                
                <HeaderUserOptions>
                    {isAuthenticated ? (
                        <>
                            <UserInfo>
                                <UserDropdown 
                                    user={user} 
                                    translations={translations} 
                                    handleLogout={handleLogout} 
                                />
                                
                                <UserCredit onClick={(event) => handlePopoverOpen(event, `${translations.credit}${user?.credit}`)}>
                                    <StyledTokenIcon />
                                    {user?.credit}
                                </UserCredit>
                            </UserInfo>
                            <LogoutButton onClick={handleLogout} translations={translations} />
                        </>
                    ) : (
                        <>
                           <UserInfo>
                                <UserName onClick={(event) => handlePopoverOpen(event, user?.full_name)}>
                                    {getInitial(user?.full_name)}
                                </UserName>
                                
                                <UserCredit onClick={(event) => handlePopoverOpen(event, `${translations.credit}${user?.credit}`)}>
                                    <StyledTokenIcon />
                                    {user?.credit}
                                </UserCredit>
                            </UserInfo> 
                           <LoginButton translations={translations} />
                           <SignupButton translations={translations} /> 
                        </>
                    )}
                    <LanguageSelector 
                        translations={translations} 
                        language={language} 
                        setLanguage={setLanguage} 
                    />
                </HeaderUserOptions>
            </HeaderDesktopNav>
        </>
    );
};

export default DesktopNav;