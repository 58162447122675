import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Paper, Button, IconButton, Dialog, DialogActions, DialogContent, DialogTitle,
  TextField, Tooltip, MenuItem, Alert, FormControl, Typography, TableSortLabel
} from '@mui/material';
import { Edit, Delete, History } from '@mui/icons-material';
import { format } from 'date-fns';  // For date formatting
import { ModalWindow } from '../ReusableComponents';

const UserManagement = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [users, setUsers] = useState([]);
  const [editUser, setEditUser] = useState(null);
  const [deleteUserId, setDeleteUserId] = useState(null);
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [isEditConfirmModalOpen, setEditConfirmModalOpen] = useState(false);
  const [isLogModalOpen, setLogModalOpen] = useState(false);
  const [userLogs, setUserLogs] = useState([]);
  const [logCount, setLogCount] = useState(0);
  const [alertMessage, setAlertMessage] = useState(null);
  const [alertSeverity, setAlertSeverity] = useState('success');
  const [validationErrors, setValidationErrors] = useState({});
  const [searchQuery, setSearchQuery] = useState('');
  const [sortConfig, setSortConfig] = useState({ sortBy: 'email', sortOrder: 'asc' });

  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const passwordPattern = /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

  const getToken = () => localStorage.getItem('token');

  const fetchUsers = useCallback((search = '', sortBy = 'email', sortOrder = 'asc') => {
    const token = getToken();
    axios.get(`${apiUrl}/users/admin/users`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: { search, sort_by: sortBy, sort_order: sortOrder }
    })
      .then(response => setUsers(response.data))
      .catch(error => console.error("Error fetching users:", error));
  }, [apiUrl]);

  useEffect(() => {
    fetchUsers();
  }, [apiUrl, fetchUsers]);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    fetchUsers(e.target.value, sortConfig.sortBy, sortConfig.sortOrder);
  };

  const handleSort = (column) => {
    const isAsc = sortConfig.sortBy === column && sortConfig.sortOrder === 'asc';
    const newSortOrder = isAsc ? 'desc' : 'asc';
    setSortConfig({ sortBy: column, sortOrder: newSortOrder });
    fetchUsers(searchQuery, column, newSortOrder);
  };

  const handleEdit = (user) => {
    setEditUser({ ...user, password: '', confirmPassword: '' });
    setEditModalOpen(true);
  };

  const handleEditChange = (e) => {
    setEditUser({ ...editUser, [e.target.name]: e.target.value });
  };

  const validateUser = (user) => {
    const errors = {};
    if (!emailPattern.test(user.email)) {
      errors.email = 'Invalid email format';
    }
    if (user.password && !passwordPattern.test(user.password)) {
      errors.password = 'Password must be at least 8 characters long, contain an uppercase letter, a number, and a special character';
    }
    if (user.password !== user.confirmPassword) {
      errors.confirmPassword = 'Passwords do not match';
    }
    return errors;
  };

  const confirmEdit = () => {
    const errors = validateUser(editUser);
    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      return;
    }
    setValidationErrors({});
    setEditConfirmModalOpen(true);
  };

  const handleConfirmEdit = () => {
    const token = getToken();
    const updatedUser = {
      email: editUser.email, 
      password: editUser.password || undefined,
      is_active: editUser.is_active,
      is_deleted: editUser.is_deleted,
      full_name: editUser.full_name,
      language: editUser.language,
      credit: editUser.credit,
      role: editUser.role,
      failed_attempts: editUser.failed_attempts,
    };

    axios.put(`${apiUrl}/users/admin/update_user/${editUser.id}`, updatedUser, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(() => {
        setUsers((prevUsers) =>
          prevUsers.map((user) =>
            user.id === editUser.id ? { ...user, ...editUser } : user
          )
        );
        setEditModalOpen(false);
        setEditConfirmModalOpen(false);
        setAlertMessage('User updated successfully');
        setAlertSeverity('success');
      })
      .catch((error) => {
        console.error("Error updating user:", error);
        setAlertMessage('Error updating user');
        setAlertSeverity('error');
      });
  };

  const handleDelete = (id) => {
    setDeleteUserId(id);
    setDeleteModalOpen(true);
  };

  const confirmDelete = (deleteType) => {
    const token = getToken();
    const endpoint = deleteType === 'hard' ? '/users/admin/hard_delete_user' : '/users/admin/soft_delete_user';

    axios.delete(`${apiUrl}${endpoint}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: { user_id: deleteUserId },
    })
      .then(() => {
        setUsers((prevUsers) => prevUsers.filter(user => user.id !== deleteUserId));
        setDeleteModalOpen(false);
        setAlertMessage('User deleted successfully');
        setAlertSeverity('success');
      })
      .catch(error => {
        console.error("Error deleting user:", error);
        setAlertMessage('Error deleting user');
        setAlertSeverity('error');
      });
  };

  const handleViewLogs = (userId) => {
    const token = getToken();
    axios.get(`${apiUrl}/users/admin/user_logs/${userId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(response => {
        setUserLogs(response.data.logs);
        setLogCount(response.data.log_count);
        setLogModalOpen(true);
      })
      .catch(error => console.error("Error fetching user logs:", error));
  };

  return (
    <div style={{ padding: '2rem' }}>
      <h1>User Management Panel</h1>
      {alertMessage && <Alert style={{ marginBottom: '10px' }} severity={alertSeverity}>{alertMessage}</Alert>}
      <TextField
        label="Search"
        value={searchQuery}
        onChange={handleSearchChange}
        fullWidth
        style={{ marginBottom: '1rem' }}
      />
      <TableContainer component={Paper} style={{ marginTop: '1rem' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>No.</TableCell>
              <TableCell>ID</TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortConfig.sortBy === 'email'}
                  direction={sortConfig.sortOrder}
                  onClick={() => handleSort('email')}
                >
                  Email
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortConfig.sortBy === 'full_name'}
                  direction={sortConfig.sortOrder}
                  onClick={() => handleSort('full_name')}
                >
                  Full Name
                </TableSortLabel>
              </TableCell>
              <TableCell>Language</TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortConfig.sortBy === 'credit'}
                  direction={sortConfig.sortOrder}
                  onClick={() => handleSort('credit')}
                >
                  Credit
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortConfig.sortBy === 'is_active'}
                  direction={sortConfig.sortOrder}
                  onClick={() => handleSort('is_active')}
                >
                  Active
                </TableSortLabel>
              </TableCell>
              <TableCell>is_deleted</TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortConfig.sortBy === 'role'}
                  direction={sortConfig.sortOrder}
                  onClick={() => handleSort('role')}
                >
                  Role
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortConfig.sortBy === 'failed_attempts'}
                  direction={sortConfig.sortOrder}
                  onClick={() => handleSort('failed_attempts')}
                >
                  Failed Attempts
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortConfig.sortBy === 'last_login'}
                  direction={sortConfig.sortOrder}
                  onClick={() => handleSort('last_login')}
                >
                  Last Login
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortConfig.sortBy === 'created_at'}
                  direction={sortConfig.sortOrder}
                  onClick={() => handleSort('created_at')}
                >
                  Created At
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortConfig.sortBy === 'updated_at'}
                  direction={sortConfig.sortOrder}
                  onClick={() => handleSort('updated_at')}
                >
                  Updated At
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortConfig.sortBy === 'last_failed_attempt'}
                  direction={sortConfig.sortOrder}
                  onClick={() => handleSort('last_failed_attempt')}
                >
                  Last Failed Attempt
                </TableSortLabel>
              </TableCell>
              <TableCell>Verification Token</TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortConfig.sortBy === 'last_email_sent'}
                  direction={sortConfig.sortOrder}
                  onClick={() => handleSort('last_email_sent')}
                >
                  Last Email Sent
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortConfig.sortBy === 'deleted_at'}
                  direction={sortConfig.sortOrder}
                  onClick={() => handleSort('deleted_at')}
                >
                  Deleted At
                </TableSortLabel>
              </TableCell>
              <TableCell>Actions</TableCell>
              <TableCell>Activity Log</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user, index) => (
              <TableRow key={user.id}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>{user.id}</TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>{user.full_name}</TableCell>
                <TableCell>{user.language}</TableCell>
                <TableCell>{user.credit}</TableCell>
                <TableCell>{user.is_active ? 'Active' : 'Inactive'}</TableCell>
                <TableCell>{user.is_deleted ? 'Deleted' : 'Not Deleted'}</TableCell>
                <TableCell>{user.role}</TableCell>
                <TableCell>{user.failed_attempts}</TableCell>
                <TableCell>{user.last_login ? format(new Date(user.last_login), 'yyyy-MM-dd HH:mm:ss') : 'N/A'}</TableCell>
                <TableCell>{format(new Date(user.created_at), 'yyyy-MM-dd HH:mm:ss')}</TableCell>
                <TableCell>{user.updated_at ? format(new Date(user.updated_at), 'yyyy-MM-dd HH:mm:ss') : 'N/A'}</TableCell>
                <TableCell>{user.last_failed_attempt ? format(new Date(user.last_failed_attempt), 'yyyy-MM-dd HH:mm:ss') : 'N/A'}</TableCell>
                <TableCell>{user.verification_token || 'N/A'}</TableCell>
                <TableCell>{user.last_email_sent ? format(new Date(user.last_email_sent), 'yyyy-MM-dd HH:mm:ss') : 'N/A'}</TableCell>
                <TableCell>{user.deleted_at ? format(new Date(user.deleted_at), 'yyyy-MM-dd HH:mm:ss') : 'N/A'}</TableCell>
                <TableCell>
                  <Tooltip title="Edit">
                    <IconButton onClick={() => handleEdit(user)}><Edit /></IconButton>
                  </Tooltip>
                  <Tooltip title="Delete">
                    <IconButton onClick={() => handleDelete(user.id)}><Delete /></IconButton>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  <Tooltip title="View Activity Log">
                    <IconButton onClick={() => handleViewLogs(user.id)}><History /></IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Edit Modal */}
      <Dialog open={isEditModalOpen} onClose={() => setEditModalOpen(false)}>
        <DialogTitle>Edit User</DialogTitle>
        <DialogContent>
          <FormControl fullWidth margin="normal">
            <TextField
              label="Full Name"
              id="full_name"
              name="full_name"
              value={editUser?.full_name || ''}
              onChange={handleEditChange}
              fullWidth
              placeholder="Enter full name"
            />
          </FormControl>
          <FormControl fullWidth margin="normal" error={!!validationErrors.email}>
            <TextField
              label="Email"
              id="email"
              name="email"
              value={editUser?.email || ''}
              onChange={handleEditChange}
              fullWidth
              error={!!validationErrors.email}
              helperText={validationErrors.email}
              placeholder="Enter email"
            />
          </FormControl>
          <FormControl fullWidth margin="normal" error={!!validationErrors.password}>
            <TextField
              label="Password"
              id="password"
              name="password"
              value={editUser?.password || ''}
              onChange={handleEditChange}
              fullWidth
              type="password"
              error={!!validationErrors.password}
              helperText={validationErrors.password}
              placeholder="Enter new password"
            />
          </FormControl>
          <FormControl fullWidth margin="normal" error={!!validationErrors.confirmPassword}>
            <TextField
              label="Confirm Password"
              id="confirmPassword"
              name="confirmPassword"
              value={editUser?.confirmPassword || ''}
              onChange={handleEditChange}
              fullWidth
              type="password"
              error={!!validationErrors.confirmPassword}
              helperText={validationErrors.confirmPassword}
              placeholder="Confirm new password"
            />
          </FormControl>
          <FormControl fullWidth margin="normal">
            <TextField
              label="Credit"
              id="credit"
              name="credit"
              value={editUser?.credit || ''}
              onChange={handleEditChange}
              fullWidth
              placeholder="Enter credit amount"
            />
          </FormControl>
          <FormControl fullWidth margin="normal">
            <TextField
              label="Role"
              select
              id="role"
              name="role"
              value={editUser?.role || ''}
              onChange={handleEditChange}
              fullWidth
            >
              <MenuItem value=""><em>Select role</em></MenuItem>
              <MenuItem value="admin">Admin</MenuItem>
              <MenuItem value="user">User</MenuItem>
            </TextField>
          </FormControl>
          <FormControl fullWidth margin="normal">
            <TextField
              label="Language"
              select
              id="language"
              name="language"
              value={editUser?.language || ''}
              onChange={handleEditChange}
              fullWidth
            >
              <MenuItem value=""><em>Select language</em></MenuItem>
              <MenuItem value="en">English</MenuItem>
              <MenuItem value="jp">Japanese</MenuItem>
            </TextField>
          </FormControl>
          <FormControl fullWidth margin="normal">
            <TextField
              label="Is Active"
              select
              id="is_active"
              name="is_active"
              value={editUser?.is_active ?? ''}
              onChange={handleEditChange}
              fullWidth
            >
              <MenuItem value=""><em>Select status</em></MenuItem>
              <MenuItem value={true}>Active</MenuItem>
              <MenuItem value={false}>Inactive</MenuItem>
            </TextField>
          </FormControl>
          <FormControl fullWidth margin="normal">
            <TextField
              label="Is Deleted"
              select
              id="is_deleted"
              name="is_deleted"
              value={editUser?.is_deleted ?? ''}
              onChange={handleEditChange}
              fullWidth
            >
              <MenuItem value=""><em>Select status</em></MenuItem>
              <MenuItem value={true}>Deleted</MenuItem>
              <MenuItem value={false}>Not Deleted</MenuItem>
            </TextField>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={confirmEdit}>Save</Button>
          <Button variant="outlined" onClick={() => setEditModalOpen(false)}>Cancel</Button>
        </DialogActions>
      </Dialog>

      {/* Delete Modal */}
      <Dialog open={isDeleteModalOpen} onClose={() => setDeleteModalOpen(false)}>
      <DialogTitle sx={{ textAlign: 'center' }}>Confirm Delete</DialogTitle>
        <DialogActions>
          <Button variant="contained" color="error" onClick={() => confirmDelete('hard')}>
            Hard Delete
          </Button>
          <Button variant="contained" color="warning" onClick={() => confirmDelete('soft')}>
            Soft Delete
          </Button>
          <Button variant="outlined" onClick={() => setDeleteModalOpen(false)}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      {/* Confirm Edit Modal */}
      <ModalWindow
        open={isEditConfirmModalOpen}
        handleClose={() => setEditConfirmModalOpen(false)}
        handleConfirm={handleConfirmEdit}
        title="Confirm Changes"
        description="Are you sure you want to save changes to this account?"
        confirmText="Save"
        cancelText="Cancel"
      />


      {/* Activity Log Modal */}
      <Dialog open={isLogModalOpen} onClose={() => setLogModalOpen(false)} maxWidth="md" fullWidth>
        <DialogTitle>Activity Log</DialogTitle>
        <DialogContent>
          <Typography variant="subtitle1">Total Logs: {logCount}</Typography>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>No.</TableCell>
                <TableCell>Endpoint</TableCell>
                <TableCell>Method</TableCell>
                <TableCell>Status Code</TableCell>
                <TableCell>Timestamp</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {userLogs.map((log, index) => (
                <TableRow key={log.id}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{log.endpoint}</TableCell>
                  <TableCell>{log.method}</TableCell>
                  <TableCell>{log.status_code}</TableCell>
                  <TableCell>{format(new Date(log.timestamp), 'yyyy-MM-dd HH:mm:ss')}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={() => setLogModalOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default UserManagement;