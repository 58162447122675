import React, { useState } from 'react';
import { Menu } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { useNavigate } from 'react-router-dom';
import DOMPurify from 'dompurify';
import {
    UserName,
    MenuItemStyled,
  } from './HeaderStyles';


const UserDropdown = ({ user, translations, handleLogout, onMenuClose }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const navigate = useNavigate();

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        if (onMenuClose) {
            onMenuClose();
        }
    };

    const isMenuOpen = Boolean(anchorEl);

    return (
        <div>
            <UserName onClick={handleMenuOpen}>
                {user?.full_name.charAt(0).toUpperCase()}
            </UserName>
            <Menu
                anchorEl={anchorEl}
                open={isMenuOpen}
                onClose={handleMenuClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                sx={{
                    '& .MuiPaper-root': {
                        backgroundColor: '#4A0072',
                        color: '#E3BBEB',
                    },
                }}
            >
                <MenuItemStyled disabled>
                    {DOMPurify.sanitize(user?.full_name)}
                </MenuItemStyled>
                <MenuItemStyled onClick={() => { navigate('/account-settings'); handleMenuClose(); }}>
                    <AccountCircleIcon sx={{ marginRight: 1 }} />
                    {translations.account_settings}
                </MenuItemStyled>
                <MenuItemStyled onClick={() => { handleLogout(); handleMenuClose(); }}>
                    <ExitToAppIcon sx={{ marginRight: 1 }} />
                    {translations.logout}
                </MenuItemStyled>
            </Menu>
        </div>
    );
};

export default UserDropdown;