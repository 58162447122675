import React, { useState } from 'react';
import { Box, Typography, Button, Alert, TextField, IconButton, InputAdornment } from '@mui/material';
import { useTranslations } from '../contexts/TranslationsContext';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/system';
import { useParams } from 'react-router-dom';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: theme.spacing(2),
  backgroundColor: '#ffffff',
  borderRadius: '8px',
  boxShadow: theme.shadows[2],
  maxWidth: 400,
  margin: 'auto',
  textAlign: 'center',
}));

const ResetButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  width: '80%', // Make the input field smaller
}));

const ResetPassword = ({ language }) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const translations = useTranslations();
  const { token } = useParams();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const navigate = useNavigate(); // Define navigate

  const validatePassword = (password) => {
    const passwordRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return passwordRegex.test(password);
  };

  const handleResetPassword = async () => {
    let valid = true;

    if (!validatePassword(password)) {
      setPasswordError(translations.passwordInvalid);
      valid = false;
    } else {
      setPasswordError('');
    }

    if (password !== confirmPassword) {
      setConfirmPasswordError(translations.passwordMismatch);
      valid = false;
    } else {
      setConfirmPasswordError('');
    }

    if (!valid) {
      return;
    }

    try {
      const response = await fetch(`${apiUrl}/email/password/reset`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token, password }),
      });

      if (response.ok) {
        setMessage(translations.passwordResetSuccess);
        setError('');
        setTimeout(() => {
            navigate('/login');  // Redirect to login page after 2 seconds
          }, 1000);
      } else {
        setError(translations.passwordResetFailed);
        setMessage('');
      }
    } catch (error) {
      setError(translations.passwordResetFailed);
      setMessage('');
    }
  };

  const toggleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const toggleShowConfirmPassword = () => {
    setShowConfirmPassword((show) => !show);
  };

  return (
    <StyledBox>
      <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold', color: '#2E073F' }}>
        {translations.resetPasswordTitle}
      </Typography>
      <StyledTextField
        label={translations.newPassword}
        variant="outlined"
        type={showPassword ? 'text' : 'password'}
        margin="normal"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        error={!!passwordError}
        helperText={passwordError}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={toggleShowPassword}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <StyledTextField
        label={translations.confirmPassword}
        variant="outlined"
        type={showConfirmPassword ? 'text' : 'password'}
        margin="normal"
        value={confirmPassword}
        onChange={(e) => setConfirmPassword(e.target.value)}
        error={!!confirmPasswordError}
        helperText={confirmPasswordError}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={toggleShowConfirmPassword}
                edge="end"
              >
                {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      {message && <Alert severity="success">{message}</Alert>}
      {error && <Alert severity="error">{error}</Alert>}
      <ResetButton variant="contained" color="primary" onClick={handleResetPassword}>
        {translations.resetPassword}
      </ResetButton>
    </StyledBox>
  );
};

export default ResetPassword;