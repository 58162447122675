import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Paper, Button, IconButton, Dialog, DialogActions, DialogContent, DialogTitle,
  TextField, Tooltip, Alert, TableSortLabel, Typography
} from '@mui/material';
import { Delete, History } from '@mui/icons-material';
import { format } from 'date-fns';  // For date formatting

const GuestUserManagement = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [guestUsers, setGuestUsers] = useState([]);
  const [deleteGuestUserId, setDeleteGuestUserId] = useState(null);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [isLogModalOpen, setLogModalOpen] = useState(false);
  const [guestUserLogs, setGuestUserLogs] = useState([]);
  const [logCount, setLogCount] = useState(0);
  const [alertMessage, setAlertMessage] = useState(null);
  const [alertSeverity, setAlertSeverity] = useState('success');
  const [searchQuery, setSearchQuery] = useState('');
  const [sortConfig, setSortConfig] = useState({ sortBy: 'fingerprint', sortOrder: 'asc' });

  const getToken = () => localStorage.getItem('token');

  const fetchGuestUsers = useCallback((search = '', sortBy = 'fingerprint', sortOrder = 'asc') => {
    const token = getToken();
    axios.get(`${apiUrl}/users/admin/guest_users`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: { search, sort_by: sortBy, sort_order: sortOrder }
    })
      .then(response => setGuestUsers(response.data))
      .catch(error => console.error("Error fetching guest users:", error));
  }, [apiUrl]);

  useEffect(() => {
    fetchGuestUsers();
  }, [apiUrl, fetchGuestUsers]);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    fetchGuestUsers(e.target.value, sortConfig.sortBy, sortConfig.sortOrder);
  };

  const handleSort = (column) => {
    const isAsc = sortConfig.sortBy === column && sortConfig.sortOrder === 'asc';
    const newSortOrder = isAsc ? 'desc' : 'asc';
    setSortConfig({ sortBy: column, sortOrder: newSortOrder });
    fetchGuestUsers(searchQuery, column, newSortOrder);
  };

  const handleDelete = (id) => {
    setDeleteGuestUserId(id);
    setDeleteModalOpen(true);
  };

  const confirmDelete = () => {
    const token = getToken();
    axios.delete(`${apiUrl}/users/admin/hard_delete_guest_user`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: { guest_user_id: deleteGuestUserId },
    })
      .then(() => {
        setGuestUsers((prevGuestUsers) => prevGuestUsers.filter(guestUser => guestUser.id !== deleteGuestUserId));
        setDeleteModalOpen(false);
        setAlertMessage('Guest user deleted successfully');
        setAlertSeverity('success');
      })
      .catch(error => {
        console.error("Error deleting guest user:", error);
        setAlertMessage('Error deleting guest user');
        setAlertSeverity('error');
      });
  };

  const handleViewLogs = (guestUserId) => {
    const token = getToken();
    axios.get(`${apiUrl}/users/admin/guest_user_logs/${guestUserId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(response => {
        setGuestUserLogs(response.data.logs);
        setLogCount(response.data.log_count);
        setLogModalOpen(true);
      })
      .catch(error => console.error("Error fetching guest user logs:", error));
  };

  return (
    <div style={{ padding: '2rem' }}>
      <h1>Guest User Management Panel</h1>
      {alertMessage && <Alert style={{ marginBottom: '10px' }} severity={alertSeverity}>{alertMessage}</Alert>}
      <TextField
        label="Search"
        value={searchQuery}
        onChange={handleSearchChange}
        fullWidth
        style={{ marginBottom: '1rem' }}
      />
      <TableContainer component={Paper} style={{ marginTop: '1rem' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>No.</TableCell>
              <TableCell>ID</TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortConfig.sortBy === 'fingerprint'}
                  direction={sortConfig.sortOrder}
                  onClick={() => handleSort('fingerprint')}
                >
                  Fingerprint
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortConfig.sortBy === 'full_name'}
                  direction={sortConfig.sortOrder}
                  onClick={() => handleSort('full_name')}
                >
                  Full Name
                </TableSortLabel>
              </TableCell>
              <TableCell>Language</TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortConfig.sortBy === 'credit'}
                  direction={sortConfig.sortOrder}
                  onClick={() => handleSort('credit')}
                >
                  Credit
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortConfig.sortBy === 'created_at'}
                  direction={sortConfig.sortOrder}
                  onClick={() => handleSort('created_at')}
                >
                  Created At
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortConfig.sortBy === 'last_login'}
                  direction={sortConfig.sortOrder}
                  onClick={() => handleSort('last_login')}
                >
                  Last Login
                </TableSortLabel>
              </TableCell>
              <TableCell>Actions</TableCell>
              <TableCell>Activity Log</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {guestUsers.map((guestUser, index) => (
              <TableRow key={guestUser.id}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>{guestUser.id}</TableCell>
                <TableCell>{guestUser.fingerprint}</TableCell>
                <TableCell>{guestUser.full_name}</TableCell>
                <TableCell>{guestUser.language}</TableCell>
                <TableCell>{guestUser.credit}</TableCell>
                <TableCell>{format(new Date(guestUser.created_at), 'yyyy-MM-dd HH:mm:ss')}</TableCell>
                <TableCell>{guestUser.last_login ? format(new Date(guestUser.last_login), 'yyyy-MM-dd HH:mm:ss') : 'N/A'}</TableCell>
                <TableCell>
                  <Tooltip title="Delete">
                    <IconButton onClick={() => handleDelete(guestUser.id)}><Delete /></IconButton>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  <Tooltip title="View Activity Log">
                    <IconButton onClick={() => handleViewLogs(guestUser.id)}><History /></IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Delete Modal */}
      <Dialog open={isDeleteModalOpen} onClose={() => setDeleteModalOpen(false)}>
      <DialogTitle sx={{ textAlign: 'center' }}>Confirm Delete</DialogTitle>
        <DialogActions>
          <Button variant="contained" color="error" onClick={confirmDelete}>
            Hard Delete
          </Button>
          <Button variant="outlined" onClick={() => setDeleteModalOpen(false)}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      {/* Activity Log Modal */}
      <Dialog open={isLogModalOpen} onClose={() => setLogModalOpen(false)} maxWidth="md" fullWidth>
        <DialogTitle>Activity Log</DialogTitle>
        <DialogContent>
          <Typography variant="subtitle1">Total Logs: {logCount}</Typography>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>No.</TableCell>
                <TableCell>Endpoint</TableCell>
                <TableCell>Method</TableCell>
                <TableCell>Status Code</TableCell>
                <TableCell>Timestamp</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {guestUserLogs.map((log, index) => (
                <TableRow key={log.id}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{log.endpoint}</TableCell>
                  <TableCell>{log.method}</TableCell>
                  <TableCell>{log.status_code}</TableCell>
                  <TableCell>{format(new Date(log.timestamp), 'yyyy-MM-dd HH:mm:ss')}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={() => setLogModalOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default GuestUserManagement;