import React, { createContext, useState, useEffect, useContext } from 'react';
import axios from 'axios';

const TranslationsContext = createContext();
const apiUrl = process.env.REACT_APP_API_URL;

export const TranslationsProvider = ({ children, language }) => {
    const [translations, setTranslations] = useState({});

    useEffect(() => {
        const fetchTranslations = async () => {
            try {
                const response = await axios.get(`${apiUrl}/data/translations/`);
                const data = response.data;

                // Transform the data to the desired format
                const transformedTranslations = {};
                data.forEach(item => {
                    transformedTranslations[item.key] = item.value[language];
                });

                setTranslations(transformedTranslations);
            } catch (error) {
                console.error('Error fetching translations:', error);
            }
        };

        fetchTranslations();
    }, [language]);

    return (
        <TranslationsContext.Provider value={translations}>
            {children}
        </TranslationsContext.Provider>
    );
};

export const useTranslations = () => {
    return useContext(TranslationsContext);
};