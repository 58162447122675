import React, { useState, useContext } from 'react';
import { Button, TextField, Typography, Box, Alert, IconButton, InputAdornment } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { AuthContext } from '../contexts/AuthContext';
import { useTranslations } from '../contexts/TranslationsContext';
import { styled } from '@mui/system';

const StyledBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2),
    backgroundColor: '#ffffff',
    borderRadius: '8px',
    boxShadow: theme.shadows[2],
    maxWidth: 400,
    margin: 'auto',
}));

const LoginButton = styled(Button)(({ theme }) => ({
    marginTop: theme.spacing(2),
}));

const ResendButton = styled(Button)(({ theme }) => ({
    marginTop: theme.spacing(2),
}));

const SignupLink = styled(Typography)(({ theme }) => ({
    marginTop: theme.spacing(2),
}));

const Login = ({ language }) => {
    const t = useTranslations();
    const apiUrl = process.env.REACT_APP_API_URL;
    const { login } = useContext(AuthContext); // Access login function from context
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [errors, setErrors] = useState({});
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState(''); // State for success message
    const [showForgotPasswordLink, setShowForgotPasswordLink] = useState(false); // State for forgot password link
    const [showRestoreUserLink, setShowRestoreUserLink] = useState(false); // State for forgot password link
    const navigate = useNavigate(); // Define navigate
    const [showResendButton, setShowResendButton] = useState(false);
    
    const validateForm = () => {
        const newErrors = {};

        if (!email) newErrors.email = t.emailRequired;
        if (!password) newErrors.password = t.passwordRequired;

        return newErrors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formErrors = validateForm();

        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors);
            setErrorMessage('');
        } else {
            const formData = new FormData();
            formData.append('username', email);
            formData.append('password', password);

            try {
                const response = await fetch(`${apiUrl}/login`, {
                    method: 'POST',
                    body: formData,
                });

                if (response.ok) {
                    const data = await response.json(); // assuming the token is in response data
                    localStorage.setItem('token', data.access_token); // Store token in localStorage
                    login(data.access_token); // Call the login function to update the token in AuthContext
                    setErrorMessage('');
                    setSuccessMessage(t.loginSuccess); // Set success message
                    setTimeout(() => {
                        navigate('/');  // Redirect to home page after 2 seconds
                    }, 1000);
                } else {
                    const data = await response.json();
                    const { message, lockout_time_remaining, attempts_left } = data.detail;
                    
                    let translatedError = t.loginFailed;

                    if (message === "Account locked. Try again later.") {
                        translatedError = `${t.accountLocked} (${Math.floor(lockout_time_remaining / 60)}${t.minute}${Math.floor(lockout_time_remaining % 60)}${t.second} ${t.lockoutTimeRemaining})`;
                    } else if (message === "Invalid credentials.") {
                        translatedError = `${t.invalidCredentials}. ${attempts_left} ${t.attemptsLeft}`;
                        setShowForgotPasswordLink(true); // Show forgot password link
                    } else if (data.detail === "Invalid credentials.") {
                        translatedError = t.invalidCredentials; 
                        setShowForgotPasswordLink(true); // Show forgot password link
                    } else if (data.detail === "Please confirm your email to activate your account.") {
                        translatedError = t.accountInactive; 
                        setShowResendButton(true);
                    } else if (data.detail === "This account has been deactivated. Please contact support.") {
                        translatedError = t.accountDeleted; 
                        setShowRestoreUserLink(true);
                    }
                    setErrorMessage(translatedError);
                }
            } catch (error) {
                setErrorMessage(t.loginFailed);
            }

            setErrors({});
        }
    };

    const handleResendVerificationEmail = async () => {
        try {
            const response = await fetch(`${apiUrl}/email/resend-verification-email`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email }),
            });
    
            if (response.ok) {
                setErrorMessage(t.verificationEmailSent);
                setShowResendButton(false);
            } else {
                setErrorMessage(t.verificationEmailFailed);
            }
        } catch (error) {
            setErrorMessage(t.verificationEmailFailed);
        }
    };
    
    const toggleShowPassword = () => {
        setShowPassword((show) => !show);
    };

    return (
        <StyledBox>
            <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold', color: '#2E073F' }}>{t.loginTitle}</Typography>
            {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
            {successMessage && <Alert severity="success">{successMessage}</Alert>} {/* Display success message */}
            <form onSubmit={handleSubmit} style={{ width: '100%' }}>
                <TextField 
                    label={t.email} 
                    variant="outlined" 
                    margin="normal" 
                    fullWidth 
                    value={email} 
                    onChange={(e) => setEmail(e.target.value)} 
                    error={!!errors.email} 
                    helperText={errors.email} 
                />
                <TextField 
                    label={t.password} 
                    variant="outlined" 
                    type={showPassword ? 'text' : 'password'} 
                    margin="normal" 
                    fullWidth 
                    value={password} 
                    onChange={(e) => setPassword(e.target.value)} 
                    error={!!errors.password} 
                    helperText={errors.password} 
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={toggleShowPassword}
                                    edge="end"
                                >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
                <LoginButton variant="contained" color="primary" fullWidth type="submit">
                    {t.submitLogin}
                </LoginButton>
                {showResendButton && (
                    <ResendButton variant="contained" color="secondary" fullWidth onClick={handleResendVerificationEmail}>
                        {t.resendVerificationEmail}
                    </ResendButton>
                )}
                {showForgotPasswordLink && (
                    <SignupLink variant="body2" align="center">
                        <Link to="/forgot-password">{t.forgotPassword}</Link>
                    </SignupLink>
                )}
                {showRestoreUserLink && (
                    <SignupLink variant="body2" align="center">
                        <Link to="/restore-account">{t.restoreUser}</Link>
                    </SignupLink>
                )}
                <SignupLink variant="body2" align="center">
                    <Link to="/signup">{t.signupLink}</Link>
                </SignupLink>
            </form>
        </StyledBox>
    );
};

export default Login;