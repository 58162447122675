// ReusableComponents.js
import React, { useState, useEffect } from 'react';
import { Button, Box, Typography, CircularProgress, Modal, Alert } from '@mui/material';
import { styled } from '@mui/system';

export const StyledModalBox = styled(Box)(({ theme }) => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2),
    backgroundColor: '#ffffff',
    borderRadius: '8px',
    boxShadow: theme.shadows[2],
    maxWidth: 400,
    width: '100%', // Ensure the modal doesn't exceed the screen width
}));

export const ModalButtonContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    marginTop: theme.spacing(2),
    gap: theme.spacing(2), // Add spacing between buttons
}));

const Frame = styled(Box)(({ theme }) => ({
  width: '400px',
  height: '300px',
  border: '2px dashed #E5A0FF', // Dashed border for a unique effect
  borderRadius: '8px',
  position: 'relative',
  overflow: 'hidden',
  backgroundColor: '#E3BBEB', // Lightest Purple for the background
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  boxShadow: theme.shadows[2], // Base shadow
  transition: 'transform 0.3s ease, box-shadow 0.3s ease', // Transition for smooth effects
  '&:hover': {
    transform: 'scale(1.05)', // Scale up on hover
    boxShadow: theme.shadows[4], // Increase shadow on hover
  },
}));

const Image = styled('img')(({ theme }) => ({
  width: '100%',
  height: '100%',
  objectFit: 'contain',
}));

const PlaceholderText = styled(Typography)(({ theme }) => ({
  position: 'absolute',
  top: '10px',
  left: '50%',
  transform: 'translateX(-50%)',
  color: '#666',
}));

const LoadingBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
}));

const UploadButton = ({ onChange, label }) => (
  <label htmlFor="upload-file">
    <input
      accept="image/*"
      style={{ display: 'none' }}
      id="upload-file"
      type="file"
      onChange={onChange}
    />
    <Button
      variant="contained"
      component="span"
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      }}
    >
      {label}
    </Button>
  </label>
);

const ProcessButton = ({ onClick, disabled, label, loading }) => (
  <Button
    variant="contained"
    color="primary"
    onClick={onClick}
    disabled={disabled || loading}
    sx={{ marginTop: '20px', position: 'relative', width: '200px', height: '48px', backgroundColor: loading ? '#ccc' : 'primary.main' }}
  >
    {label}
  </Button>
);

const DownloadButton = ({ label, onClick }) => (
  <Button
    variant="contained"
    color="secondary"
    //href
    onClick={onClick}
    sx={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    }}
  >
    {label}
  </Button>
);

const UploadImageFrame = ({ previewImage, translations, handleFileChange }) => (
  <Frame>
    {previewImage ? (
      <Image src={previewImage} alt="Preview" />
    ) : (
      <PlaceholderText variant="h6">
        {translations.uploadButton}
      </PlaceholderText>
    )}
    <UploadButton onChange={handleFileChange} label={translations.uploadButton} />
  </Frame>
);

const DownloadImageFrame = ({ previewImage, resultImage, loading, translations, onDownloadClick }) => (
  <Frame>
    {loading && (
      <LoadingBox>
        <CircularProgress size={48} />
        <Typography variant="body1" sx={{ marginTop: '10px', color: '#666' }}>
          {translations.loading}
        </Typography>
      </LoadingBox>
    )}
    {resultImage ? (
      <Image src={resultImage} alt="Processed" />
    ) : (
      !loading && (
        <PlaceholderText variant="h6">
          {translations.downloadButton}
        </PlaceholderText>
      )
    )}
    {resultImage && (
      <DownloadButton label={translations.downloadButton} onClick={onDownloadClick} />
    )}
  </Frame>
);

const ModalWindow = ({ open, handleClose, handleConfirm, title, description, confirmText, cancelText }) => {
    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
        >
            <StyledModalBox>
                <Typography id="modal-title" variant="h6" component="h2" gutterBottom>
                    {title}
                </Typography>
                {description && (
                    <Typography id="modal-description" sx={{ mb: 2 }}>
                        {description}
                    </Typography>
                )}
                <ModalButtonContainer>
                    <Button variant="contained" color="primary" onClick={handleConfirm}>
                        {confirmText}
                    </Button>
                    <Button variant="outlined" color="secondary" onClick={handleClose}>
                        {cancelText}
                    </Button>
                </ModalButtonContainer>
            </StyledModalBox>
        </Modal>
    );
};



const SmoothAlert = ({ message, severity, onClose }) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (message) {
      setVisible(true);
      setTimeout(() => {
        setVisible(false);
        setTimeout(onClose, 300); // Allow the fade-out animation to complete before calling onClose
      }, 3000); // Keep the message visible for 3 seconds
    }
  }, [message, onClose]);

  return (
    <Box
      sx={{
        opacity: visible ? 1 : 0,
        transition: 'opacity 0.5s ease-in-out', // Smooth fade effect
        pointerEvents: visible ? 'auto' : 'none', // Prevent interaction while fading out
      }}
    >
      <Alert severity={severity} onClose={onClose}>
        {message}
      </Alert>
    </Box>
  );
};

export { ProcessButton, DownloadImageFrame, UploadImageFrame, ModalWindow, SmoothAlert };