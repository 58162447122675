import React from 'react';
import { Menu } from '@mui/material';
import LanguageIcon from '@mui/icons-material/Language';
import {
    HeaderButton,
    MenuItemStyled,
  } from './HeaderStyles';


const LanguageSelector = ({ language, setLanguage, translations, onLanguageChange }) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = async (lang) => {
        const token = localStorage.getItem('token');
        const url = token
                        ? `${apiUrl}/users/language/logged/`
                        : `${apiUrl}/users/language/guest`;

        try {
            const response = await fetch(url, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token') || localStorage.getItem('guestToken')}`,
                },
                body: JSON.stringify({ language: lang }),
            });
    
            if (response.ok) {
              setLanguage(lang);
              setAnchorEl(null);
              if (onLanguageChange) {
                  onLanguageChange();
              }
            } else {
              console.error('Failed to update language');
            }
          } catch (error) {
            console.error('Error updating language:', error);
          }
        
    };
    
    return (
        <div>
            <HeaderButton
                onClick={handleClick}
                startIcon={<LanguageIcon />}
            >
                {translations.language}
            </HeaderButton>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => handleClose(language)}
                sx={{
                    '& .MuiPaper-root': {
                        backgroundColor: '#4A0072', // Deep Purple
                        color: '#E3BBEB',           // Lightest Purple text
                    },
                }}
            >
                <MenuItemStyled onClick={() => handleClose('en')}>English</MenuItemStyled>
                <MenuItemStyled onClick={() => handleClose('jp')}>日本語</MenuItemStyled>
            </Menu>
        </div>
    );
};

export default LanguageSelector;