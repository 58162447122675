import React from 'react';
import { styled } from '@mui/system';
import { Typography, Box, Link as MuiLink } from '@mui/material';
import { Link } from 'react-router-dom';
import { useTranslations } from '../contexts/TranslationsContext';

const FooterContainer = styled(Box)(({ theme }) => ({
    padding: '10px 20px',
    backgroundColor: '#1A001D', // Darkest Purple
    color: '#E3BBEB',           // Lightest Purple for text
    textAlign: 'center',
}));

const FooterLinks = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    gap: theme.spacing(2),
    marginTop: theme.spacing(1),
}));

const Footer = () => {
    const t = useTranslations();
    return (
        <FooterContainer component="footer">
            <Typography variant="body2">
                {t.copyright}
            </Typography>
            <FooterLinks>
                <MuiLink component={Link} to="/privacy-policy" color="inherit">
                    {t['privacy_policy.title']}
                </MuiLink>
                <MuiLink component={Link} to="/term-of-service" color="inherit">
                    {t['tos.title']}
                </MuiLink>
            </FooterLinks>
        </FooterContainer>
    );
};

export default Footer;