import React, { useState, useContext, useEffect } from 'react';
import { Button, TextField, Typography, Box, Alert, Modal, IconButton, InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';
import { useTranslations } from '../contexts/TranslationsContext';
import { styled } from '@mui/system';

const StyledBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2),
    backgroundColor: '#ffffff',
    borderRadius: '8px',
    boxShadow: theme.shadows[2],
    maxWidth: 400,
    margin: 'auto',
}));

const StyledModalBox = styled(Box)(({ theme }) => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2),
    backgroundColor: '#ffffff',
    borderRadius: '8px',
    boxShadow: theme.shadows[2],
    width: 400,
    margin: 'auto',
}));

const AccountSettings = ({ language }) => {
    const t = useTranslations();
    const apiUrl = process.env.REACT_APP_API_URL;
    const { isAuthenticated, logout } = useContext(AuthContext); // Access isAuthenticated and logout function from context
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showCurrentPassword, setShowCurrentPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [showConfirmDeletePassword, setShowConfirmDeletePassword] = useState(false);
    const [errors, setErrors] = useState({});
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [open, setOpen] = useState(false); // State to manage the modal open/close
    const [confirmDeletePassword, setConfirmDeletePassword] = useState(''); // State for delete confirmation password
    const navigate = useNavigate(); // Define navigate

    useEffect(() => {
        if (!isAuthenticated) {
            alert(t.notAuthenticated);
            navigate('/');
        }
    }, [isAuthenticated, navigate, t]);

    const validateForm = () => {
        const newErrors = {};
        const passwordPattern = /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

        if (!currentPassword) newErrors.currentPassword = t.passwordRequired;
        if (!newPassword) {
            newErrors.newPassword = t.passwordRequired;
        } else if (!passwordPattern.test(newPassword)) {
            newErrors.newPassword = t.passwordInvalid;
        }
        if (newPassword !== confirmPassword) {
            newErrors.confirmPassword = t.passwordMismatch;
        }

        return newErrors;
    };

    const handleInputChange = (setter, field) => (e) => {
        setter(e.target.value);
        setErrors((prevErrors) => ({ ...prevErrors, [field]: '' }));
    };

    const handleChangePassword = async (e) => {
        e.preventDefault();
        const formErrors = validateForm();

        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors);
            setSuccessMessage('');
        } else {
            try {
                const response = await fetch(`${apiUrl}/users/change_password`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    },
                    body: JSON.stringify({
                        current_password: currentPassword,
                        new_password: newPassword,
                    }),
                });

                if (response.ok) {
                    setSuccessMessage(t.passwordChangeSuccess);
                    setErrorMessage('');
                    setErrors({});
                    // Reset fields
                    setCurrentPassword('');
                    setNewPassword('');
                    setConfirmPassword('');
                } else {
                    const data = await response.json();
                    if (data.detail === "Current password is incorrect") {
                        setErrors({ currentPassword: t.currentPasswordIncorrect });
                    } else {
                        setErrorMessage(t.passwordChangeFailed);
                    }
                    setSuccessMessage('');
                }
            } catch (error) {
                setErrorMessage(t.passwordChangeFailed);
                setSuccessMessage('');
            }
        }
    };

    const handleDeleteAccount = () => {
        setOpen(true); // Open the modal
    };

    const handleCloseModal = () => {
        setOpen(false);
        setConfirmDeletePassword(''); // Reset password input on close
    };

    const handleConfirmDelete = async () => {
        try {
            const response = await fetch(`${apiUrl}/users/delete_account`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json', // Include content type
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
                body: JSON.stringify({ confirm_password: confirmDeletePassword }), // Send password in body
            });

            if (response.ok) {
                setSuccessMessage(t.accountDeleteSuccess);
                setErrorMessage('');
                handleCloseModal(); // Close the modal on success
                setTimeout(() => {
                    logout(); // Logout user
                    navigate('/signup');
                }, 2000); // Navigate after 2 seconds
            } else {
                const data = await response.json();
                if (data.detail === "Invalid credentials") {
                    setErrorMessage(t.currentPasswordIncorrect);
                    handleCloseModal(); // Close the modal on incorrect password
                    
                } else {
                    setErrorMessage(t.accountDeleteFailed);
                    handleCloseModal(); // Close the modal on incorrect password
                }
                setSuccessMessage('');
            }
        } catch (error) {
            setErrorMessage(t.accountDeleteFailed);
            setSuccessMessage('');
        }
    };

    const toggleShowPassword = (setter) => () => {
        setter((show) => !show);
    };

    return (
        <StyledBox>
            <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold', color: '#2E073F' }}>{t.settingsTitle}</Typography>
            {successMessage && <Alert severity="success">{successMessage}</Alert>}
            {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
            <form onSubmit={handleChangePassword} style={{ width: '100%' }}>
                <TextField 
                    label={t.currentPassword} 
                    variant="outlined" 
                    type={showCurrentPassword ? 'text' : 'password'} 
                    margin="normal" 
                    fullWidth 
                    value={currentPassword} 
                    onChange={handleInputChange(setCurrentPassword, 'currentPassword')} 
                    error={!!errors.currentPassword} 
                    helperText={errors.currentPassword} 
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={toggleShowPassword(setShowCurrentPassword)}
                                    edge="end"
                                >
                                    {showCurrentPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
                <TextField 
                    label={t.newPassword} 
                    variant="outlined" 
                    type={showNewPassword ? 'text' : 'password'} 
                    margin="normal" 
                    fullWidth 
                    value={newPassword} 
                    onChange={handleInputChange(setNewPassword, 'newPassword')} 
                    error={!!errors.newPassword} 
                    helperText={errors.newPassword} 
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={toggleShowPassword(setShowNewPassword)}
                                    edge="end"
                                >
                                    {showNewPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
                <TextField 
                    label={t.confirmPassword} 
                    variant="outlined" 
                    type={showConfirmPassword ? 'text' : 'password'} 
                    margin="normal" 
                    fullWidth 
                    value={confirmPassword} 
                    onChange={handleInputChange(setConfirmPassword, 'confirmPassword')} 
                    error={!!errors.confirmPassword} 
                    helperText={errors.confirmPassword} 
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={toggleShowPassword(setShowConfirmPassword)}
                                    edge="end"
                                >
                                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
                <Button variant="contained" color="primary" fullWidth type="submit" sx={{ marginTop: 2 }}>{t.changePassword}</Button>
            </form>
            <Button variant="contained" color="secondary" fullWidth onClick={handleDeleteAccount} sx={{ marginTop: 2 }}>{t.deleteAccount}</Button>

            <Modal open={open} onClose={handleCloseModal}>
                <StyledModalBox>
                    <Typography variant="h6" component="h2" gutterBottom>{t.confirmDelete}</Typography>
                    <TextField 
                        label={t.currentPassword} 
                        variant="outlined" 
                        type={showConfirmDeletePassword ? 'text' : 'password'} 
                        margin="normal" 
                        fullWidth 
                        value={confirmDeletePassword} 
                        onChange={(e) => setConfirmDeletePassword(e.target.value)}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => setShowConfirmDeletePassword(!showConfirmDeletePassword)}
                                        edge="end"
                                    >
                                        {showConfirmDeletePassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <Button variant="contained" color="primary" onClick={handleConfirmDelete} fullWidth sx={{ marginTop: 2 }}>
                        {t.deleteAccount}
                    </Button>
                    <Button variant="outlined" color="secondary" onClick={handleCloseModal} fullWidth sx={{ marginTop: 2 }}>
                        {t.cancel}
                    </Button>
                </StyledModalBox>
            </Modal>

        </StyledBox>
    );
};

export default AccountSettings;