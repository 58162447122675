import React, { useState, useRef, useContext } from 'react';
import { AppBar, Toolbar, Typography, IconButton, Popover } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from 'react-router-dom';
import DesktopNav from './HeaderComponents/DesktopNav'; // Import DesktopNav
import MobileSideBar from './HeaderComponents/MobileSideBar'; // Import MobileSideBar
import { UserContext } from '../contexts/UserContext'; // Import UserContext
import { useTranslations } from '../contexts/TranslationsContext';
import { styled } from '@mui/system';
import { ModalWindow} from './ReusableComponents';
import { ReactSVG } from 'react-svg'; // Import ReactSVG
import junaiLogo from './media/junai_logo.svg'; // Import the logo

const HeaderAppBar = styled(AppBar)(({ theme }) => ({
    backgroundColor: '#2E073F',
}));

const HeaderBrand = styled('div')(({ theme }) => ({
    flexGrow: 1,
    cursor: 'pointer',
    display: 'flex',         // Use flexbox to center the content
    alignItems: 'center',    // Vertically center the SVG
    
    '& svg': {
        height: '40px',
    },
}));

const HumbugerMenu = styled(IconButton)(({ theme }) => ({
    color: '#E3BBEB', // Lightest Purple for the icon color
    backgroundColor: '#4A0072', // Deep Purple for the background
    boxShadow: '0 6px 12px rgba(26, 0, 29, 0.3)', // Darkest Purple for the shadow
    borderRadius: '8px', // Add border radius for rounded corners
    transition: 'background-color 0.3s ease, box-shadow 0.3s ease', // Smooth transition
    '&:hover': {
        backgroundColor: '#9C44AA', // Bright Purple for hover effect
        boxShadow: '0 8px 16px rgba(26, 0, 29, 0.4)', // Enhanced shadow on hover
    },
}));

const HeaderPopover = styled(Popover)(({ theme }) => ({
    '.MuiPaper-root': {
        backgroundColor: '#4A0072',
        color: '#E3BBEB',
        padding: '10px',
    },
}));

const Header = ({ language, setLanguage, isAuthenticated, logout }) => {
    const translations = useTranslations();
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [popoverContent, setPopoverContent] = useState('');
    const [menuAnchorEl, setMenuAnchorEl] = useState(null);
    const [logoutModalOpen, setLogoutModalOpen] = useState(false); // State for logout modal
    const serviceButton = useRef(null);
    const navigate = useNavigate();
    const { user } = useContext(UserContext); // Access user data from context

    const toggleDrawer = (open) => () => {
        setDrawerOpen(open);
    };

    const handleLogout = () => {
        logout();
        setLogoutModalOpen(false);
        navigate('/login');
    };

    const handlePopoverOpen = (event, content) => {
        setAnchorEl(event.currentTarget);
        setPopoverContent(content);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
        setPopoverContent('');
    };

    const handleMenuClose = () => {
        setMenuAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const menuOpen = Boolean(menuAnchorEl);

    return (
        <HeaderAppBar position="static">
            <Toolbar>
                <HeaderBrand onClick={() => navigate('/')}>
                    <ReactSVG src={junaiLogo} className="svg" />
                </HeaderBrand>
                
                
                <DesktopNav
                    isAuthenticated={isAuthenticated}
                    user={user}
                    language={language}
                    translations={translations}
                    handleLogout={() => setLogoutModalOpen(true)}
                    handlePopoverOpen={handlePopoverOpen}
                    serviceButton={serviceButton}
                    setLanguage={setLanguage}
                />
                
                <HumbugerMenu
                    edge="end"
                    aria-label="menu"
                    onClick={toggleDrawer(true)}
                    sx={{ display: { xs: 'flex', md: 'none' } }}
                >
                    <MenuIcon />
                </HumbugerMenu>
                
                <MobileSideBar
                    drawerOpen={drawerOpen}
                    toggleDrawer={toggleDrawer}
                    isAuthenticated={isAuthenticated}
                    user={user}
                    language={language}
                    translations={translations}
                    handleLogout={() => setLogoutModalOpen(true)}
                    handlePopoverOpen={handlePopoverOpen}
                    menuAnchorEl={menuAnchorEl}
                    menuOpen={menuOpen}
                    handleMenuClose={handleMenuClose}
                    setLanguage={setLanguage}
                />
                
                
            </Toolbar>
            
            <HeaderPopover
                open={open}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Typography>{popoverContent}</Typography>
            </HeaderPopover>
            
            <ModalWindow
                open={logoutModalOpen}
                handleClose={() => setLogoutModalOpen(false)}
                handleConfirm={handleLogout}
                translations = {translations}
                title={translations.confirmLogout}
                description={''}
                confirmText={translations.confirm}
                cancelText={translations.cancel}
            />

        </HeaderAppBar>
    );
};

export default Header;