import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Paper, Button, IconButton, Dialog, DialogActions, DialogContent, DialogTitle,
  TextField, Tooltip, Alert, TableSortLabel
} from '@mui/material';
import { Edit, Delete, Add } from '@mui/icons-material';
import { ModalWindow } from '../ReusableComponents';

const TranslationManagement = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [translations, setTranslations] = useState([]);
  const [editTranslation, setEditTranslation] = useState(null);
  const [deleteTranslationKey, setDeleteTranslationKey] = useState(null);
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [isAddModalOpen, setAddModalOpen] = useState(false);
  const [isAddConfirmModalOpen, setAddConfirmModalOpen] = useState(false);
  const [isEditConfirmModalOpen, setEditConfirmModalOpen] = useState(false);
  const [newTranslation, setNewTranslation] = useState({
    key: '', value_en: '', value_jp: ''
  });
  const [validationErrors, setValidationErrors] = useState({});
  const [alertMessage, setAlertMessage] = useState(null);
  const [alertSeverity, setAlertSeverity] = useState('success');
  const [searchQuery, setSearchQuery] = useState('');
  const [sortConfig, setSortConfig] = useState({ sortBy: 'key', sortOrder: 'asc' });

  const getToken = () => localStorage.getItem('token');

  const fetchTranslations = useCallback((search = '', sortBy = 'key', sortOrder = 'asc') => {
    const token = getToken();
    axios.get(`${apiUrl}/data/translations_admin`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: { search, sort_by: sortBy, sort_order: sortOrder }
    })
    .then(response => {
      const formattedTranslations = response.data.map(translation => ({
        ...translation,
        value_en: translation.value.en,
        value_jp: translation.value.jp
      }));
      setTranslations(formattedTranslations);
    })
    .catch(error => console.error("Error fetching translations:", error));
  }, [apiUrl]);

  useEffect(() => {
    fetchTranslations();
  }, [apiUrl, fetchTranslations]);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    fetchTranslations(e.target.value, sortConfig.sortBy, sortConfig.sortOrder);
  };

  const handleSort = (column) => {
    const isAsc = sortConfig.sortBy === column && sortConfig.sortOrder === 'asc';
    const newSortOrder = isAsc ? 'desc' : 'asc';
    setSortConfig({ sortBy: column, sortOrder: newSortOrder });
    fetchTranslations(searchQuery, column, newSortOrder);
  };

  const handleEdit = (translation) => {
    setEditTranslation(translation);
    setEditModalOpen(true);
  };

  const handleEditChange = (e) => {
    setEditTranslation({ ...editTranslation, [e.target.name]: e.target.value });
  };

  const validateTranslation = (translation) => {
    const errors = {};
    if (!translation.key) errors.key = 'Key is required';
    if (!translation.value_en) errors.value_en = 'English value is required';
    if (!translation.value_jp) errors.value_jp = 'Japanese value is required';
    return errors;
  };

  const confirmEdit = () => {
    const errors = validateTranslation(editTranslation);
    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      return;
    }
    setValidationErrors({});
    setEditConfirmModalOpen(true);
  };

  const handleConfirmEdit = () => {
    const token = getToken();
    const updatedTranslation = {
      value: {
        en: editTranslation.value_en,
        jp: editTranslation.value_jp
      }
    };

    axios.put(`${apiUrl}/data/translations/${editTranslation.key}`, updatedTranslation, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(() => {
        setTranslations((prevTranslations) =>
          prevTranslations.map((translation) =>
            translation.key === editTranslation.key ? { ...translation, ...editTranslation } : translation
          )
        );
        setEditModalOpen(false);
        setEditConfirmModalOpen(false);
        setAlertMessage('Translation updated successfully');
        setAlertSeverity('success');
      })
      .catch((error) => {
        console.error("Error updating translation:", error);
        setAlertMessage('Error updating translation');
        setAlertSeverity('error');
      });
  };

  const confirmDelete = () => {
    const token = getToken();

    axios.delete(`${apiUrl}/data/translations/${deleteTranslationKey}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    })
      .then(() => {
        setTranslations((prevTranslations) => prevTranslations.filter(translation => translation.key !== deleteTranslationKey));
        setDeleteModalOpen(false);
        setAlertMessage('Translation deleted successfully');
        setAlertSeverity('success');
      })
      .catch(error => {
        console.error("Error deleting translation:", error);
        setAlertMessage('Error deleting translation');
        setAlertSeverity('error');
      });
  };

  const handleAddTranslation = () => {
    const errors = validateTranslation(newTranslation);
    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      return;
    }
    setValidationErrors({});
    setAddConfirmModalOpen(true);
  };

  const handleConfirmAddTranslation = () => {
    const token = getToken();
    const newTranslationData = [{
      key: newTranslation.key,
      value: {
        en: newTranslation.value_en,
        jp: newTranslation.value_jp
      }
    }];

    axios.post(`${apiUrl}/data/translations`, newTranslationData, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    })
      .then(response => {
        setTranslations(prevTranslations => [
          ...prevTranslations,
          {
            id: response.data.translations[0].id,  // Assuming the response contains the new translation's ID
            key: newTranslation.key,
            value_en: newTranslation.value_en,
            value_jp: newTranslation.value_jp,
            created_at: response.data.translations[0].created_at,
            updated_at: response.data.translations[0].updated_at
          }
        ]);
        setAddModalOpen(false);
        setAddConfirmModalOpen(false);
        setNewTranslation({ key: '', value_en: '', value_jp: '' });
        setAlertMessage('Translation added successfully');
        setAlertSeverity('success');
      })
      .catch(error => {
        console.error("Error adding translation:", error);
        setAlertMessage('Error adding translation');
        setAlertSeverity('error');
      });
  };

  return (
    <div style={{ padding: '2rem' }}>
      <h1>Translation Management Panel</h1>
      {alertMessage && <Alert style={{ marginBottom: '10px' }} severity={alertSeverity}>{alertMessage}</Alert>}
      <TextField
        label="Search"
        value={searchQuery}
        onChange={handleSearchChange}
        fullWidth
        style={{ marginBottom: '1rem' }}
      />
      <Button variant="contained" startIcon={<Add />} onClick={() => setAddModalOpen(true)}>
        Add Translation
      </Button>

      <TableContainer component={Paper} style={{ marginTop: '1rem' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>No.</TableCell>
              <TableCell>ID</TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortConfig.sortBy === 'key'}
                  direction={sortConfig.sortOrder}
                  onClick={() => handleSort('key')}
                >
                  Key
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortConfig.sortBy === 'value_en'}
                  direction={sortConfig.sortOrder}
                  onClick={() => handleSort('value_en')}
                >
                  Value (EN)
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortConfig.sortBy === 'value_jp'}
                  direction={sortConfig.sortOrder}
                  onClick={() => handleSort('value_jp')}
                >
                  Value (JP)
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortConfig.sortBy === 'created_at'}
                  direction={sortConfig.sortOrder}
                  onClick={() => handleSort('created_at')}
                >
                  Created At
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={sortConfig.sortBy === 'updated_at'}
                  direction={sortConfig.sortOrder}
                  onClick={() => handleSort('updated_at')}
                >
                  Updated At
                </TableSortLabel>
              </TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {translations.map((translation, index) => (
              <TableRow key={translation.id}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>{translation.id}</TableCell>
                <TableCell>{translation.key}</TableCell>
                <TableCell>{translation.value_en}</TableCell>
                <TableCell>{translation.value_jp}</TableCell>
                <TableCell>{new Date(translation.created_at).toLocaleString()}</TableCell>
                <TableCell>{new Date(translation.updated_at).toLocaleString()}</TableCell>
                <TableCell>
                  <Tooltip title="Edit">
                    <IconButton onClick={() => handleEdit(translation)}><Edit /></IconButton>
                  </Tooltip>
                  <Tooltip title="Delete">
                    <IconButton onClick={() => { setDeleteTranslationKey(translation.key); setDeleteModalOpen(true); }}><Delete /></IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Edit Modal */}
      <Dialog open={isEditModalOpen} onClose={() => setEditModalOpen(false)}>
        <DialogTitle>Edit Translation</DialogTitle>
        <DialogContent>
          {['value_en', 'value_jp'].map(field => (
            <TextField
              key={field}
              margin="dense"
              label={field}
              name={field}
              value={editTranslation?.[field] || ''}
              onChange={handleEditChange}
              fullWidth
              multiline
              rows={4} // Allow multiple lines
              error={!!validationErrors[field]}
              helperText={validationErrors[field]}
            />
          ))}
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" onClick={confirmEdit}>
            Save Changes
          </Button>
          <Button variant="outlined" color="secondary" onClick={() => setEditModalOpen(false)}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete Confirmation Modal */}
      <ModalWindow
        open={isDeleteModalOpen}
        handleClose={() => setDeleteModalOpen(false)}
        handleConfirm={confirmDelete}
        title="Confirm Deletion"
        description="Are you sure you want to delete this translation?"
        confirmText="Delete"
        cancelText="Cancel"
      />

      {/* Add Translation Modal */}
      <Dialog open={isAddModalOpen} onClose={() => setAddModalOpen(false)}>
        <DialogTitle>Add Translation</DialogTitle>
        <DialogContent>
          {['key', 'value_en', 'value_jp'].map(field => (
            <TextField
              key={field}
              margin="dense"
              label={field}
              name={field}
              value={newTranslation[field]}
              onChange={(e) => setNewTranslation({ ...newTranslation, [e.target.name]: e.target.value })}
              fullWidth
              multiline
              rows={4} // Allow multiple lines
              error={!!validationErrors[field]}
              helperText={validationErrors[field]}
            />
          ))}
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" onClick={handleAddTranslation}>
            Add Translation
          </Button>
          <Button variant="outlined" color="secondary" onClick={() => setAddModalOpen(false)}>
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* Add Confirmation Modal */}
      <ModalWindow
        open={isAddConfirmModalOpen}
        handleClose={() => setAddConfirmModalOpen(false)}
        handleConfirm={handleConfirmAddTranslation}
        title="Confirm Addition"
        description="Are you sure you want to add this translation?"
        confirmText="Add"
        cancelText="Cancel"
      />

      {/* Edit Confirmation Modal */}
      <ModalWindow
        open={isEditConfirmModalOpen}
        handleClose={() => setEditConfirmModalOpen(false)}
        handleConfirm={handleConfirmEdit}
        title="Confirm Changes"
        description="Are you sure you want to save changes to this translation?"
        confirmText="Save"
        cancelText="Cancel"
      />
    </div>
  );
};

export default TranslationManagement;